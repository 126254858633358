import { isBrowser } from '@/utils/environment';
import { navigateTo } from '@/utils/navigateTo';
import { logger } from '@gik/analytics/utils/logger';
import { useInkind } from '@gik/api/inkinds/inkind';
import { patchInkind } from '@gik/api/inkinds/inkinds';
import { deactivateOrRemoveOrganizerPage } from '@gik/api/users/user';
import withSuspense from '@gik/core/components/suspended/withSuspense';
import { timeoutDefaultValue } from '@gik/core/constants';
import type { InkindPageAPIModel, PatchInkindPageRequest } from '@gik/core/models/gik/InkindPage';
import routes from '@gik/core/routes';
import { useUserStore } from '@gik/core/store/UserStore';
import bemBlock from '@gik/core/utils/bemBlock';
import { extractCampaignSlug, getGoFundMeURL } from '@gik/core/utils/GoFundMe';
import { openIntercomWindow } from '@gik/core/utils/Intercom';
import { scrollIntoView } from '@gik/core/utils/scroll';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { CashAppFormInputNames } from '@gik/create/enums/CashAppFormInputNames';
import { GoFundMeFormInputNames } from '@gik/create/enums/GoFundMeFormInputNames';
import { PayPalFormInputNames } from '@gik/create/enums/PayPalFormInputNames';
import { VenmoFormInputNames } from '@gik/create/enums/VenmoFormInputNames';
import { cashtagRegex, paypalMeUsernameRegex, venmoRegex } from '@gik/create/formSchemas';
import { usePageGoFundMeCampaign } from '@gik/inkind-page/api/GoFundMeAPI';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { useInkindPageRecipientInfoComplete } from '@gik/inkind-page/utils/isInkindPageRecipientInfoComplete';
import { Button } from '@gik/ui/Button';
import type { FormSchemaEntry } from '@gik/ui/Form';
import { Form } from '@gik/ui/Form';
import { InterrogationRed } from '@gik/ui/SvgIcon/GikIcons/InterrogationRed';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import TrashOutlineIcon from '@heroicons/react/outline/TrashIcon';
import he from 'he';
import { useRouter } from 'next/router';
import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { scroller } from 'react-scroll';
import type { featuresSectionSuggestedItems, FeaturesSectionValues } from './FeaturesSection/FeaturesSection';
import { FeaturesSection } from './FeaturesSection/FeaturesSection';
import { modalContentId } from './InkindPageSettingsModal';
// important, import Skeleton component like this and not from @gik/inkind-page
// or it will be undefined and fail silently, circular dependency issue
import { InkindPageSettingsSkeleton } from './InkindPageSettingsSkeleton';
import InkindPageUpgradeSection from './InkindPageUpgradeSection/InkindPageUpgradeSection';
import { OrganizersSection } from './OrganizersSection/OrganizersSection';
import type { PageTitleValues } from './PageTitleSection/PageTitleFieldSet';
import { pageTitleFieldSetSchema } from './PageTitleSection/pageTitleFieldSetSchema';
import { PageTitleSection } from './PageTitleSection/PageTitleSection';
import type { PrivacySettingsValues } from './PrivacySettingsSection/PrivacySettingsSection';
import { PrivacySettingsSection } from './PrivacySettingsSection/PrivacySettingsSection';
import type { RecipientInfoValues } from './RecipientInfoSection/RecipientInfoFieldSet';
import { recipientInfoFieldSetSchema } from './RecipientInfoSection/recipientInfoFieldSetSchema';
import { RecipientInfoSection } from './RecipientInfoSection/RecipientInfoSection';
import type { PageSettingsNavItemIdsType } from './utils/PageSettingsScrollNavBar';
import { pageSettingsNavItemIds } from './utils/PageSettingsScrollNavBar';
import { Text } from '@gik/ui/Text/Text';
import { useUserWallets } from '@gik/user-profile/hooks/useUserWallets';

export const inkindPageSettingsFormBlockName = 'inkind-page-settings-form';

type PageSettingsFormValues = PageTitleValues & RecipientInfoValues;
type PageSettingsValues = PageSettingsFormValues & PrivacySettingsValues & FeaturesSectionValues;
export type PageSettingsScrollToType = PageSettingsNavItemIdsType | typeof featuresSectionSuggestedItems;

export interface IInkindPageSettingsFormProps {
  onClose?: () => void;
  inkindRouteId: string;
  buttonsPortal?: () => HTMLElement;
  scrollToSectionId?: PageSettingsScrollToType;
}

export const buttonsNonModalPortalId = 'buttons-non-modal-portal-id';
export const buttonsNonModalPortal = () => document.getElementById(buttonsNonModalPortalId);

const InkindPageSettingsFormComp: React.FC<IInkindPageSettingsFormProps> = ({
  onClose,
  inkindRouteId,
  buttonsPortal,
  scrollToSectionId,
}): React.ReactElement => {
  const formId = 'inkind-page-settings-form-id';

  const bem = bemBlock(inkindPageSettingsFormBlockName);
  const { t } = useTranslation();
  const userId = useUserStore(state => state.id);
  const router = useRouter();

  // NOTE: fix highlighting first item in scroll navbar
  // since first 4 section ids are wrapped with a form tag and Organizers section isn't
  // Organizers nav item gets incorrectly highlighted
  React.useEffect(() => {
    // if in modal
    if (isBrowser() && buttonsPortal) {
      // wont work without minimal delay
      setTimeout(() => {
        const contentEl = document.getElementById(modalContentId);
        contentEl?.dispatchEvent(new CustomEvent('scroll'));
      }, timeoutDefaultValue);
    }
  }, [buttonsPortal]);

  // states, sections with handlers
  const [featuresSectionValues, setFeaturesSectionValues] = React.useState<FeaturesSectionValues>(null);
  const [privacySettingsValues, setPrivacySettingsValues] = React.useState<PrivacySettingsValues>(null);

  // it takes some time to render the form
  // block rendering Organizers section before the form
  const [isFormReady, setIsFormReady] = React.useState<boolean>(false);

  const [deletePageLoading, setDeletePageLoading] = React.useState<boolean>(false);

  const { data: inkindPage, error: inkindPageError, mutate: mutateInkindPage } = useInkind(inkindRouteId);
  const { mutate: mutateGoFundMeCampaignValidity } = usePageGoFundMeCampaign(inkindPage);

  const isRecipientInfoComplete = useInkindPageRecipientInfoComplete(inkindPage);

  const timeoutRef = React.useRef<NodeJS.Timeout>(null);

  const { data: wallets, error } = useUserWallets(userId);

  // const { data: groups } = useGroups({
  //   userId,
  //   page: 1,
  //   per_page: 10,
  // });

  // scroll to section when modal opens
  React.useEffect(() => {
    // NOTE: must wait for Form to load and then scroll
    if (!isBrowser() || !buttonsPortal || !scrollToSectionId || !isFormReady) {
      return;
    }

    // small delay is needed for Features section to expand
    setTimeout(() => {
      scroller.scrollTo(scrollToSectionId, {
        duration: 300,
        smooth: true,
        containerId: modalContentId,
      });
    }, 1200);
  }, [scrollToSectionId, buttonsPortal, isFormReady]);

  React.useEffect(() => {
    if (!inkindPage) {
      return;
    }

    const initialFeaturesSectionValues = getInitialFeaturesSectionValues(inkindPage);
    const initialPrivacySettingsValues = getInitialPrivacySettingsValues(inkindPage);
    setFeaturesSectionValues(initialFeaturesSectionValues);
    setPrivacySettingsValues(initialPrivacySettingsValues);

    setTimeout(() => {
      scroller.scrollTo(scrollToSectionId, {
        duration: 300,
        smooth: true,
        containerId: modalContentId,
      });
    }, 1200);
  }, [inkindPage, scrollToSectionId]);

  const getInitialPrivacySettingsValues = (inkindPage: InkindPageAPIModel): PrivacySettingsValues => {
    return {
      // can be enabled and still have number and name defined
      phoneCallsEnabled: inkindPage.phoneCalls,
      alternateNumber: inkindPage.phoneCallRequest?.phone || '', // added to model
      alternateNumberOwnerName: inkindPage.phoneCallRequest?.name || '',
      flowersEnabled: inkindPage.flowers,
      textMessagesEnabled: inkindPage.phoneCallRequest?.IsTextsOk || false,
      visitorsEnabled: inkindPage.visitors,
      showPageEnabled: inkindPage.isPublic,
      // named same in GET and PATCH
      pageSharingEnabled: inkindPage.allowPageSharing,
      featurePageEnabled: inkindPage.canFeature,
      searchEnginesEnabled: inkindPage.allowCrawlers,
    };
  };

  const getInitialFeaturesSectionValues = (inkindPage: InkindPageAPIModel): FeaturesSectionValues => {
    let paypalEmail = inkindPage.financialContributions?.paypalEmail;
    if (paypalEmail && !paypalMeUsernameRegex.test(paypalEmail)) {
      paypalEmail = null;
    }

    let venmoUrl = inkindPage.financialContributions?.venmoUrl;
    if (venmoUrl && !venmoRegex.test(venmoUrl)) {
      venmoUrl = null;
    }

    let cashAppUrl = inkindPage.financialContributions?.cashAppUrl;
    if (cashAppUrl && !cashtagRegex.test(cashAppUrl)) {
      cashAppUrl = null;
    }

    return {
      careCalendarEnabled: inkindPage.isCalendar,
      wishlistValues: {
        enabled: inkindPage.wishList,
        // can be disabled and still have url defined
        amazonWishlistEnabled: inkindPage.amazonWishlist,
        amazonValue: inkindPage.amazonWishlistRequest?.amazonWishlistUrl || '', // null from backend
        isAmazonValueValid: true, // server sends valid data
      },
      donationValues: {
        enabled: inkindPage.payPal, // will be renamed
        [GoFundMeFormInputNames.PageURL]: getGoFundMeURL(inkindPage),
        [PayPalFormInputNames.EmailAddress]: paypalEmail || '',
        [VenmoFormInputNames.VenmoAddress]: venmoUrl || '',
        [CashAppFormInputNames.CashAppAddress]: cashAppUrl || '',
        // server sends valid data
        isPayPalValueValid: true,
        isGoFundMeValueValid: true,
        isVenmoValueValid: true,
        isCashAppValueValid: true,
      },
      sendVisaCardEnabled: inkindPage.allowVisaCard,
      sendGiftBoxEnabled: inkindPage.CTA, // will be renamed
      sendCareCardEnabled: inkindPage.allowCareCard,
      discussions: inkindPage.allowDiscussions,
      enableSuggestedArticles: inkindPage.enableSuggestedArticles,
    };
  };

  const getFeaturesSectionValid = () =>
    featuresSectionValues?.wishlistValues.isAmazonValueValid &&
    featuresSectionValues?.donationValues.isPayPalValueValid &&
    featuresSectionValues?.donationValues.isGoFundMeValueValid &&
    featuresSectionValues?.donationValues.isVenmoValueValid &&
    featuresSectionValues?.donationValues.isCashAppValueValid;

  const getInitialFormValues = (inkindPage: InkindPageAPIModel): PageSettingsFormValues => {
    if (!inkindPage) {
      return null;
    }

    return {
      // Page Title section
      pageTitle: he.decode(inkindPage.title),
      // Recipient Info section
      recipientFullName: inkindPage.recipientFullName,
      address1: inkindPage.address,
      address2: inkindPage.address2,
      city: inkindPage.city,
      state: inkindPage.stateCode,
      postalCode: inkindPage.zip,
      recipientEmail: inkindPage.recipientEmail,
      confirmRecipientEmail: inkindPage.recipientEmail,
    };
  };

  // handle form bellow
  const schema: FormSchemaEntry[] = React.useMemo(() => {
    return [...pageTitleFieldSetSchema(), ...recipientInfoFieldSetSchema()];
  }, []);

  const handleClick = () => {
    document.getElementById(formId).dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };

  const handleSubmit = async (formData: PageSettingsFormValues) => {
    // merge with values from handlers
    const pageSettingsValues: PageSettingsValues = { ...formData, ...featuresSectionValues, ...privacySettingsValues };

    const patchInkindPageData: PatchInkindPageRequest = {
      enabledFeatures: {
        calendar: pageSettingsValues.careCalendarEnabled,
        wishlist: pageSettingsValues.wishlistValues.enabled,
        amazonWishlist: pageSettingsValues.wishlistValues.amazonWishlistEnabled,
        donations: pageSettingsValues.donationValues.enabled,
        giftboxes: pageSettingsValues.sendGiftBoxEnabled,
        careCard: pageSettingsValues.sendCareCardEnabled,
        visaCard: pageSettingsValues.sendVisaCardEnabled,
        discussions: pageSettingsValues.discussions,
      },
      amazonWishlistUrl: pageSettingsValues.wishlistValues.amazonValue,
      paypalEmail: pageSettingsValues.donationValues.payPalValue,
      goFundMeUrl: extractCampaignSlug(pageSettingsValues.donationValues.gofundmeValue),
      venmoUrl: pageSettingsValues.donationValues[VenmoFormInputNames.VenmoAddress],
      cashAppUrl: pageSettingsValues.donationValues[CashAppFormInputNames.CashAppAddress],
      pageTitle: pageSettingsValues.pageTitle,
      recipient: {
        // forward recipientType unchanged from inkindPage, required in type
        type: inkindPage.recipientType,
        fullName: pageSettingsValues.recipientFullName,
        address1: pageSettingsValues.address1,
        address2: pageSettingsValues.address2,
        city: pageSettingsValues.city,
        state: pageSettingsValues.state,
        postalCode: pageSettingsValues.postalCode,
        email: pageSettingsValues.recipientEmail,
      },
      privacySettings: {
        isPublic: pageSettingsValues.showPageEnabled,
        canFeature: pageSettingsValues.featurePageEnabled,
        allowCrawlers: pageSettingsValues.searchEnginesEnabled,
        allowPageSharing: pageSettingsValues.pageSharingEnabled,
        allowPhoneCalls: pageSettingsValues.phoneCallsEnabled,
        allowTexts: pageSettingsValues.textMessagesEnabled,
        allowVisitors: pageSettingsValues.visitorsEnabled,
        allowFlowers: pageSettingsValues.flowersEnabled,
      },
      alternateContact: {
        phoneNumber: pageSettingsValues.alternateNumber,
        fullName: pageSettingsValues.alternateNumberOwnerName,
      },
      enableSuggestedArticles: pageSettingsValues.enableSuggestedArticles,
    };

    // no optimistic updates here because we need the modal to close only after an actual
    // round trip to the server to make sure the page doesnt flash while new information is still to come
    await patchInkind(inkindRouteId, patchInkindPageData);

    await mutateInkindPage();
    await mutateGoFundMeCampaignValidity();

    if (onClose) {
      onClose();
    }
  };

  // collect values via form
  // PageTitleSection,
  // RecipientInfoSection,
  //
  // collect values via onChange handlers
  // FeaturesSection,
  // PrivacySettingsSection,
  //
  // independent section with it's own form and endpoints
  // OrganizersSection,

  const handleDeletePage = async () => {
    const response = await UI.confirm(
      <Text html>
        {t(
          wallets?.length && wallets.find(wallet => wallet.customerId === inkindRouteId)
            ? translationKeys.deactivatePageConfirmationText
            : translationKeys.deactivatePageConfirmationTextNoWallets
        ).toString()}
      </Text>,
      {
        title: t(translationKeys.deactivatePage).toString(),
        okButtonProps: { variant: 'danger' },
      }
    );

    if (!response) {
      return;
    }

    try {
      setDeletePageLoading(true);
      await deactivateOrRemoveOrganizerPage(userId, inkindRouteId);
      setDeletePageLoading(false);

      // NOTE: not needed to the modal will be closed when navigation is completed
      // redirect to user profile, only on success
      setTimeout(() => {
        navigateTo(routes.userProfile);
      });
    } catch (error) {
      setDeletePageLoading(false);

      logger.error('Failed to Deactivate page.', { inkindRouteId, error });
      UI.notifyError('There was an error deactivating your page. Please try again later.');
    }
  };

  const scrollToFirstInvalidField = (): void => {
    if (!isBrowser()) {
      return;
    }

    const elementWithScroll = buttonsPortal ? document.querySelector('.gik-modal__content-wrapper') : null;
    const firstInvalidField = document.querySelector(
      '.gik-inkind-page-settings-form__form .gik-form-group__error'
    ) as HTMLElement;
    const offset = -43; // text field height + margin, 40 + 3

    if (!firstInvalidField) {
      return;
    }

    scrollIntoView(firstInvalidField, {
      offset,
      container: elementWithScroll as HTMLElement,
    });
  };

  // buttons for the form, skeleton has it's own definition for buttons
  const getButtons = (valid: boolean, submitting: boolean): JSX.Element => {
    const preventDelete = submitting || deletePageLoading;
    const preventIntercom = preventDelete;
    const preventSave = submitting || deletePageLoading;

    return (
      <footer className={bem('footer', [{ 'is-not-modal': !buttonsPortal }])}>
        <Button
          key="deactivate-page"
          variant="danger"
          onClick={() => !preventDelete && handleDeletePage()}
          prepend={<SvgIcon Icon={TrashOutlineIcon} />}
          disabled={submitting}
          loading={deletePageLoading}
        >
          {t(translationKeys.deletePage).toString()}
        </Button>
        <Button variant="default-light" onClick={() => !preventIntercom && openIntercomWindow()} circle>
          <SvgIcon Icon={InterrogationRed} />
        </Button>
        <Button key="cancel" onClick={() => onClose && onClose()} variant="default">
          {t(translationKeys.cancel).toString()}
        </Button>
        <Button
          key="save"
          variant="primary"
          type="submit"
          loading={submitting}
          disabled={!valid}
          onClick={() => !preventSave && (valid ? handleClick() : scrollToFirstInvalidField())}
        >
          {t(translationKeys.save).toString()}
        </Button>
      </footer>
    );
  };

  const isLoading = !inkindPage;
  const isError = isLoading && inkindPageError;

  if (isError) {
    return <InkindPageSettingsSkeleton buttonsPortal={buttonsPortal} />;
  }

  const initialFormValues = getInitialFormValues(inkindPage);

  return (
    <div className={bem()}>
      <Form
        onSubmit={handleSubmit}
        onReady={() => setIsFormReady(true)}
        // onChange={handleChange}
        initialValues={initialFormValues}
        schema={schema}
        disableScrollToFirstError
        vertical
        id={formId}
        restoreAfterUpdate
        className={bem('form')}
        render={({ isValid, isSubmitting }) => {
          const isAllValid = isValid && getFeaturesSectionValid();
          const buttons = getButtons(isAllValid, isSubmitting);

          return (
            <>
              <PageTitleSection id={pageSettingsNavItemIds.title} inkindPage={inkindPage} />
              {featuresSectionValues && (
                <FeaturesSection
                  id={pageSettingsNavItemIds.features}
                  values={featuresSectionValues}
                  onChange={setFeaturesSectionValues}
                  isRecipientInfoComplete={isRecipientInfoComplete}
                />
              )}
              <PrivacySettingsSection
                id={pageSettingsNavItemIds.privacy}
                values={privacySettingsValues}
                onChange={setPrivacySettingsValues}
                variant="page-settings"
              />
              <RecipientInfoSection id={pageSettingsNavItemIds.recipient} />

              {ReactDOM.createPortal(buttons, buttonsPortal ? buttonsPortal() : buttonsNonModalPortal())}
            </>
          );
        }}
      />
      {isFormReady && (
        <OrganizersSection
          routeId={inkindRouteId}
          id={pageSettingsNavItemIds.organizers}
          className={bem('organizers-section')}
        />
      )}
      {inkindPage && !inkindPage.isPremium && <InkindPageUpgradeSection />}
      {/* when form is not loaded in a modal */}
      <div id={buttonsNonModalPortalId} />
    </div>
  );
};

export const InkindPageSettingsForm = withComponentErrorBoundary(
  withSuspense(InkindPageSettingsFormComp, InkindPageSettingsSkeleton)
);
