import type { IOAuthUserLoginResponse } from '@gik/api/users/types';
import ExternalLoginForm from '@gik/auth/components/ExternalLogin/ExternalLoginForm';
import { translationKeys } from '@gik/auth/components/SignInFlow/i18n/en';
import type { ExternalLoginProvider } from '@gik/auth/utils/LoginProvider';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { Decoder } from '@gik/ui/Decoder';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { Input } from '@gik/ui/Input';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ISignInFlowOAuthContentProps = UIComponent & {
  email: string;
  supportersFirstName: string;
  provider: ExternalLoginProvider;
  onSuccess: (values: IOAuthUserLoginResponse) => void;
  goToStartStep: () => void;
  onIncorrectProvider?: (email: string) => Promise<void>;
  routeId: string;
};

export function SignInFlowOAuthContent({
  email,
  supportersFirstName,
  provider,
  className,
  onSuccess,
  goToStartStep,
  onIncorrectProvider,
  routeId,
  ...otherProps
}: ISignInFlowOAuthContentProps) {
  const bem = useBemCN('sign-in-flow-oauth-content');
  const { t } = useTranslation();

  return (
    <section {...bem(null, null, className)} {...otherProps}>
      <h1 {...bem('title')}>
        <Decoder text={t(translationKeys.loginContentTitle, { supportersFirstName })} />
      </h1>
      <p {...bem('copy')}>
        <HTMLParser rawHtml={t(translationKeys.oauthContentCopy, { provider })} />
      </p>

      <div {...bem('form-wrapper')}>
        <Input variant={'default-solid'} disabled value={email} />
        <ExternalLoginForm
          onSuccess={onSuccess}
          filter={provider}
          onIncorrectProvider={onIncorrectProvider}
          walletId={routeId}
        />
      </div>
    </section>
  );
}
