import { translationKeys } from '@gik/core/form/i18n/en';
import { RecipientInfoStepInputNames } from '@gik/create/enums/RecipientInfoStepInputNames';
import i18n from '@gik/i18n';
import type { FormSchemaEntry } from '@gik/ui/Form';
import type { SelectOptionsType } from '@gik/ui/Select';
import { SvgIcon } from '@gik/ui/SvgIcon';
import CheckIcon from '@heroicons/react/solid/CheckIcon';
import React from 'react';
import { isEmail } from '../utils/validator';

export const recipientFullNameRegex = /\//i;
export const recipientFullNameField = (customProps?: Partial<FormSchemaEntry>): FormSchemaEntry => ({
  name: RecipientInfoStepInputNames.RecipientName,
  required: true,
  minLength: 2,
  maxLength: 255,
  placeholder: i18n.t(translationKeys.recipientFullNamePlaceholder),
  ...(customProps ?? {}),
  type: 'text',
  props: { autoComplete: 'full-name', ...(customProps?.props ?? {}) },
  customValidation: [
    {
      // returns false when field is valid
      validate: (value: string) => recipientFullNameRegex.test(value),
      message: i18n.t(translationKeys.fieldHasInvalidCharacters),
    },
    ...(customProps?.customValidation ?? []),
  ],
});

export const emailAddressesField = (
  customProps?: Partial<FormSchemaEntry>,
  isImpersonating?: boolean
): FormSchemaEntry => ({
  name: 'emailAddresses',
  placeholder: 'Separate emails with commas and press enter',
  required: true,
  errorName: 'Emails',
  ...(customProps ?? {}),
  type: 'creatable-select',
  customValidation: [
    {
      // returns false when field is valid
      validate: (values: object[]) => {
        if (!values) return false;

        const nonEmailValues: string[] = [];
        // validate that all values are emails
        values.forEach(v => {
          if (!isEmail(v.value)) {
            nonEmailValues.push(v.value);
          }
        });

        if (nonEmailValues?.length) {
          return true;
        }

        return false;
      },
      message: 'All values must be email addresses',
    },

    {
      // returns false when field is valid
      validate: (values: object[]) => {
        if (!values || isImpersonating) return false;

        const emailValues: string[] = [];
        // validate that all values are emails
        values.forEach(v => {
          if (isEmail(v.value)) {
            emailValues.push(v.value);
          }
        });

        if (emailValues?.length > 200) {
          return true;
        }

        return false;
      },
      message: 'Maximum 200 email allowed at a time',
    },
    ...(customProps?.customValidation ?? []),
  ],
  props: {
    rows: 1,
    autoGrow: true,
    noresize: true,
    className: 'tw-h-24',
    ...(customProps?.props ?? {}),
  },
});

export const groupRoleIdSelectField = (
  roleOptions: SelectOptionsType[],
  customProps?: Partial<FormSchemaEntry>
): FormSchemaEntry => ({
  name: 'roleId',
  required: true,
  ...(customProps ?? {}),
  type: 'select',
  props: {
    options: roleOptions,
    variant: 'plain',
    className: 'gik-role-selector',
    selectedIcon: <SvgIcon Icon={CheckIcon} />,
    ...(customProps?.props ?? {}),
  },
});
