import { useGikSettings } from '@gik/api/acf/gik-settings/gikSettings';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import type { CartItemAbstract } from '@gik/core/models/gik/Order';
import type { PerfectGiftDelivery } from '@gik/core/models/perfectgift/PerfectGiftDelivery';
import type { WordpressPGShipping } from '@gik/core/models/wordpress/WordpressPGShipping';
import React from 'react';
import { useProducts } from '../api';

export type ShippingMethod = {
  uuid?: string;
  title: string;
  settings: {
    cost: {
      value: string;
    };
  };
};

function convertPerfectGiftDeliveryToShippingMethod(
  perfectGiftDelivery: PerfectGiftDelivery,
  allowedPGShippingMethods: WordpressPGShipping[],
  useWalletValue?: boolean
): ShippingMethod {
  return {
    uuid: perfectGiftDelivery.uuid,
    title:
      allowedPGShippingMethods.find(shippingMethod => shippingMethod.uuid === perfectGiftDelivery.uuid)?.name ??
      perfectGiftDelivery.name,
    settings: {
      cost: {
        value: allowedPGShippingMethods.find(shippingMethod => shippingMethod.uuid === perfectGiftDelivery.uuid)[
          useWalletValue ? 'wallet_value' : 'value'
        ],
      },
    },
  };
}

type WooCommerceShipping = 'WooCommerce';
type PerfectgiftShipping = 'Perfectgift';
type ShippingType = WooCommerceShipping | PerfectgiftShipping;

export function useShippingMethods(shippingType?: ShippingType, cart?: CartItemAbstract[], useWalletValue?: boolean) {
  const [shippingMethods, setShippingMethods] = React.useState<ShippingMethod[] | undefined>(undefined);
  const { data: products } = useProducts({
    productIds: cart?.length ? cart?.map(item => item.productId) : null,
    perPage: cart?.length,
  });
  const { data: gikSettings } = useGikSettings();

  React.useEffect(() => {
    if (shippingType === 'WooCommerce') {
      (async () => {
        const result = await dotnetApi.get('orders/shippingMethods', {});
        setShippingMethods(await result.json());
      })();
    }
  }, [shippingType]);

  if (shippingType === 'Perfectgift') {
    if (!gikSettings?.acf?.pg_shipping) return [];

    const allowedPGShippingMethods = gikSettings.acf.pg_shipping;

    let result = cart?.map((cartItem, i) => {
      const _products = products
        ?.find(p => p.id == cartItem.productId)
        ?.variations?.find(v => v.id === cartItem.variationId)
        ?.metaData?.find(m => m.key === 'pg_deliveries')?.value as unknown as PerfectGiftDelivery[];

      return _products
        ?.filter(
          deliveryMethod =>
            allowedPGShippingMethods.find(
              allowedDeliveryMethod =>
                deliveryMethod.uuid === allowedDeliveryMethod.uuid &&
                (useWalletValue ? allowedDeliveryMethod.wallet_enabled : allowedDeliveryMethod.enabled)
            ) && deliveryMethod.delivery_style !== 'bulk'
        )
        ?.map(deliveryMethod =>
          convertPerfectGiftDeliveryToShippingMethod(deliveryMethod, allowedPGShippingMethods, useWalletValue)
        );
    });

    result = result?.filter(item => item !== undefined && item?.length > 0);

    return result?.[0];
  }

  return shippingMethods;
}
