import type { AxiosRequestConfig } from 'axios';
import type { PlatformManagementApiResponse, StrapiPagedDataSource, StrapiSingleDataSource } from '../types/Response';
import BaseResource from './base';
import type { GiftRedeemPayload, WalletEntity, WalletMergeGiftsForCardLinkingPayload } from '../types/Wallet';
import { giftWalletEndPoints } from './endPoints';
import { type GiftEntity } from '../types/Gift';
const wallet = giftWalletEndPoints.wallet;

type Entity = WalletEntity;
type SingleDataType = StrapiSingleDataSource<Entity>;
type SingleResponseType = PlatformManagementApiResponse<SingleDataType>;
type PaginatedDataType = StrapiPagedDataSource<Entity>;
type PaginatedResponseType = PlatformManagementApiResponse<PaginatedDataType>;

export class WalletResource extends BaseResource {
  activateByCustomerId(id: string, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, boolean>(wallet.activateByCustomerId(id), options);
  }

  deactivateByCustomerId(id: string, markAsUsed: boolean, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, boolean>(wallet.deactivateByCustomerId(id, markAsUsed), options);
  }

  redeem(customerId: string, payload: GiftRedeemPayload, options?: AxiosRequestConfig) {
    return this.client.post<StrapiSingleDataSource<GiftEntity>, PlatformManagementApiResponse<GiftEntity>>(
      wallet.redeem(customerId),
      payload,
      options
    );
  }

  updateByCustomerId(id: string, payload: Partial<Entity>, options?: AxiosRequestConfig) {
    return this.client.put<SingleDataType, SingleResponseType>(wallet.updateByCustomerId(id), payload, options);
  }

  async getByUuid(id: string, options?: AxiosRequestConfig) {
    return await this.client.get<SingleDataType, PaginatedResponseType>(wallet.getBy(), {
      ...options,
      ...{
        params: {
          filters: {
            uuid: {
              $eq: id,
            },
          },
          ...options?.params,
        },
      },
    });
  }

  async getByCustomerId(id: string, options?: AxiosRequestConfig) {
    return await this.client.get<SingleDataType, PlatformManagementApiResponse<WalletEntity>>(
      wallet.getByCustomerId(id),
      options
    );
  }

  async getSupporters(customerId: string, giftTypeId?: string, options?: AxiosRequestConfig) {
    return await this.client.get<SingleDataType, PlatformManagementApiResponse<WalletEntity>>(
      wallet.getSupporters(customerId, giftTypeId),
      options
    );
  }

  createOrderGifts(options?: AxiosRequestConfig) {
    return this.client.post<boolean>(wallet.createOrderGifts(), undefined, options);
  }

  mergeGiftsForCardLinking(
    customerId: string,
    payload: WalletMergeGiftsForCardLinkingPayload,
    options?: AxiosRequestConfig
  ) {
    return this.client.post<StrapiSingleDataSource<GiftEntity>, PlatformManagementApiResponse<GiftEntity>>(
      wallet.mergeGiftsForCardLinking(customerId),
      payload,
      options
    );
  }

  // getByCustomerIdRedeemed(id: string, options?: AxiosRequestConfig) {
  //   return this.client.get<PaginatedDataType, PaginatedResponseType>(wallet.list(), options);
  // }
}
