import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AccordionItem } from './AccordionItem';
import { useBemCN } from '@gik/core/utils/bemBlock';
import type { UIComponent } from '@gik/core/types/UI';
import type { HeadingLevel } from '@gik/ui/Heading';
import { timeoutDefaultValue } from '@gik/core/constants';

export interface AccordionItemType {
  title: React.ReactNode;
  content: React.ReactNode;
  isOpen?: boolean;
}

export type AccordionProps = {
  ref?: React.Ref<AccordionImperativeProps>;
  autoCloseItems?: boolean;
  items?: AccordionItemType[];
  dividers?: boolean;
  customDivider?: React.ReactNode;
  allToggled?: boolean;
  /**
   * Use this prop to force all items to recalculate height.
   * Useful when item's content height changes at some later point in time.
   * e.g. validation messages show/hide, collapsing on a breakpoint
   */
  recalculateHeightCallback?: (fn: () => void) => void;
  headingLevel?: HeadingLevel;
  openItem?: string | number; // New prop to specify which item should be open
  activeIndices?: number[];
  toggleCollapse?: boolean;
} & UIComponent;

export type AccordionImperativeProps = {
  setActiveIndices: (indices: number[]) => void;
};

function AccordionComp(
  {
    children,
    toggleCollapse,
    autoCloseItems = true,
    className,
    items,
    dividers,
    allToggled,
    recalculateHeightCallback,
    headingLevel,
    activeIndices = [],
    openItem,
    customDivider,
    ...otherProps
  }: React.PropsWithChildren<AccordionProps>,
  forwardedRef: React.ForwardedRef<AccordionImperativeProps>
): React.ReactElement {
  const blockRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const bem = useBemCN('accordion');

  const activeIndicesRef = useRef<number[]>(activeIndices);

  React.useImperativeHandle(forwardedRef, () => ({
    setActiveIndices: (indices: number[]) => {
      activeIndicesRef.current = indices;
      setTimeout(() => {
        _recalculateHeight();
      }, 10);
    },
  }));

  // Recalculate height for all expanded items
  const _recalculateHeight = React.useCallback((timeout = 500) => {
    blockRef.current &&
      Array.from(blockRef.current.childNodes).forEach((node, index) => {
        const el = node as HTMLDivElement;
        const mainEl = el.lastChild as HTMLDivElement;

        const itemIsOpen = activeIndicesRef.current.indexOf(index) > -1;

        if (mainEl && itemIsOpen) {
          mainEl.style.height = 'auto';

          setTimeout(() => {
            // NOTE: this line needs to be inside setTimeout too
            // transition takes time, let it run and then sample the height
            // most transitions are ~300ms, larger timeout is needed
            const contentHeight = mainEl.offsetHeight;
            mainEl.style.height = contentHeight + 'px';
          }, timeout);
        }
      });
  }, []);

  // Function to collapse all items
  const collapseAll = React.useCallback(() => {
    if (!blockRef.current) {
      return;
    }

    Array.from(blockRef.current.childNodes).forEach(node => {
      const el = node as HTMLDivElement;
      const mainEl = el.lastChild as HTMLDivElement;
      if (mainEl) {
        mainEl.style.height = '0px';
      }
    });
    activeIndicesRef.current = [];
  }, []);

  // Function to handle toggling items
  const handleToggle = useCallback(
    (ev: React.MouseEvent<HTMLDivElement> | null, index: number, forceOpen = false) => {
      const itemEl = ev?.currentTarget?.parentElement || (blockRef.current?.childNodes[index] as HTMLDivElement);
      const mainEl = itemEl?.lastChild as HTMLDivElement;

      const activeIndices = activeIndicesRef.current;
      const itemIsOpen = activeIndices.indexOf(index) > -1;

      let newActiveIndices = activeIndices.concat([]);

      if (mainEl) {
        if (itemIsOpen && !forceOpen) {
          if (toggleCollapse) collapseAll();

          if (autoCloseItems) return;
          mainEl.style.height = '0px';
          newActiveIndices.splice(newActiveIndices.indexOf(index), 1);
        } else {
          if (autoCloseItems) {
            collapseAll();
            newActiveIndices = [];
          }

          mainEl.style.height = 'auto';
          const contentHeight = mainEl.offsetHeight;
          mainEl.style.height = '0px';

          setTimeout(() => {
            mainEl.style.height = contentHeight + 'px';
          }, timeoutDefaultValue);
          newActiveIndices.push(index);
        }
      }

      activeIndicesRef.current = newActiveIndices;
    },
    [autoCloseItems, collapseAll]
  );

  // Function to open all items
  const openAll = React.useCallback(() => {
    if (!blockRef.current) {
      return;
    }

    const newActiveIndices = [];

    Array.from(blockRef.current.childNodes).forEach((node, index) => {
      const el = node as HTMLDivElement;
      const mainEl = el.lastChild as HTMLDivElement;
      if (mainEl) {
        mainEl.style.height = 'auto';
        const contentHeight = mainEl.offsetHeight;
        mainEl.style.height = '0px';

        newActiveIndices.push(index);
        setTimeout(() => {
          mainEl.style.height = contentHeight + 'px';
        }, timeoutDefaultValue);
      }
    });
    activeIndicesRef.current = newActiveIndices;
  }, []);

  useEffect(() => {
    recalculateHeightCallback && recalculateHeightCallback(_recalculateHeight);
  }, [recalculateHeightCallback, _recalculateHeight]);

  // Effect to open an item by default based on the `openItem` prop
  useEffect(() => {
    if (openItem !== undefined && recalculateHeightCallback) {
      let itemIndex: number | null = null;

      if (typeof openItem === 'number') {
        itemIndex = openItem;
      } else if (typeof openItem === 'string') {
        itemIndex = items?.findIndex(item => item.title === openItem) ?? null;
      }

      if (itemIndex !== null && itemIndex !== -1) {
        setActiveIndex(itemIndex);
      }

      setTimeout(() => {
        console.log('TEST');
        recalculateHeightCallback && recalculateHeightCallback(_recalculateHeight);
      }, 100);
    }
  }, [openItem, items, recalculateHeightCallback, _recalculateHeight]);

  useEffect(() => {
    setTimeout(() => {
      _recalculateHeight();
    }, 10);
  }, [_recalculateHeight, recalculateHeightCallback]);

  // Effect to handle opening the item when the activeIndex changes
  useEffect(() => {
    if (activeIndex !== null) {
      handleToggle(null, activeIndex, true);
    }
  }, [activeIndex, handleToggle]);

  // Effect to handle `allToggled` prop
  useEffect(() => {
    if (allToggled === false) {
      collapseAll();
    } else if (allToggled === true) {
      openAll();
    }
  }, [collapseAll, openAll, allToggled]);

  return (
    <div {...bem(null, [{ dividers }], className)} {...otherProps} ref={blockRef}>
      {items?.map((item, index) => {
        const isOpen = activeIndicesRef.current.indexOf(index) > -1;

        return (
          <AccordionItem
            key={index}
            header={item.title}
            isOpen={isOpen}
            onToggle={ev => handleToggle(ev, index)}
            headerAsHeading={headingLevel !== undefined}
            headingLevel={headingLevel}
          >
            {item.content}
          </AccordionItem>
        );
      })}
      {!items &&
        React.Children.map(children, (child, index) => {
          if (!React.isValidElement(child)) {
            return null;
          }

          return (
            <>
              {dividers && index > 0 ? customDivider || <hr /> : null}
              {React.cloneElement(child, {
                // @ts-ignore
                onToggle: (ev: React.MouseEvent<HTMLDivElement>) => handleToggle(ev, index),
                isOpen: activeIndicesRef.current.indexOf(index) > -1,
              })}
            </>
          );
        })}
    </div>
  );
}

// export const Accordion = AccordionComp;

export const Accordion = React.forwardRef<AccordionImperativeProps, AccordionProps>(
  AccordionComp
) as React.ForwardRefExoticComponentExtended<AccordionProps>;
