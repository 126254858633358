import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { useWPApi } from '@gik/core/api/swr/useWPApi';
import type { BrandCategory, IWordpressBrandCategory } from '@gik/calendar/models/BrandCategory';
import React from 'react';
import he from 'he';

const path = 'brand-category';

function getParams() {
  return {
    _fields: ['id', 'name', 'parent', 'acf'],
    'proxy-cms-pages': 'all',
  };
}

export function useBrandCategoriesWP(config?: SWRConfigInterface & { disabled?: boolean }) {
  const { data: wpBrandCategories } = useWPApi<IWordpressBrandCategory[]>(
    config?.disabled ? null : path,
    getParams(),
    config
  );

  return wpBrandCategories;
}

export function useBrandCategories(config?: SWRConfigInterface & { disabled?: boolean }) {
  const wpBrandCategories = useBrandCategoriesWP(config);

  return React.useMemo(() => {
    if (!wpBrandCategories) return null;

    const brandCategories = wpBrandCategories
      .map(c => ({
        ...c,
        name: he.decode(c.name),
        acf: {
          ...c.acf,
          weight: parseInt(c.acf.weight as unknown as string) || 0,
        },
      }))
      .filter(c => c.parent === 0)
      .map(c => c as BrandCategory)
      .sort((a, b) => a.acf.weight - b.acf.weight);

    brandCategories.forEach(c => {
      c.children = wpBrandCategories
        .filter(_c => _c.parent === c.id)
        .map(
          c =>
            ({
              ...c,
              name: he.decode(c.name),
              acf: {
                ...c.acf,
                weight: parseInt(c.acf.weight as unknown as string) || 0,
              },
            }) as BrandCategory
        )
        .sort((a, b) => a.acf.weight - b.acf.weight);
    });

    return brandCategories;
  }, [wpBrandCategories]);
}
