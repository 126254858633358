import { isMobile } from '@/utils/environment';
import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { useSiteSettings } from '@gik/api/siteSettings/siteSettings';
import { auth } from '@gik/auth';
import { SignInFlowCalloutBlock } from '@gik/auth/components/SignInFlow/SignInFlowCallout';
import { SignInFlowContentCopyVariant } from '@gik/auth/components/SignInFlow/SignInFlowStartContent';
import type { ICalendarEntry, ICalendarEvent } from '@gik/calendar/models/Calendar';
import { calendarEventClaimFormContentId, checkoutFormContentId } from '@gik/calendar/utils/CalendarModals';
import type { ClaimConflictErrorDetails } from '@gik/checkout/api';
import { refillBillingAddressForm, type BillingFormValues } from '@gik/checkout/components/BillingForm/BillingForm';
import type { CardCarrierFormValues } from '@gik/checkout/components/CardCarrier/CardCarrierForm';
import type { CheckoutFormPayload, CheckoutFormValues } from '@gik/checkout/components/CheckoutForm/CheckoutForm';
import { ExpressCheckout } from '@gik/checkout/components/ExpressCheckout/ExpressCheckout';
import { useOrderTaxCalculation } from '@gik/checkout/components/useOrderTaxCalculation';
import { useShippingMethods } from '@gik/checkout/components/useShippingMethods';
import { useCheckoutFormModalStore } from '@gik/checkout/store/CheckoutFormModalStore';
import { useCheckoutStore } from '@gik/checkout/store/CheckoutStore';
import { openAddToCheckoutModal } from '@gik/checkout/utils/openAddToCheckoutModal';
import { usePerfectGiftCarrierPrices } from '@gik/checkout/utils/usePerfectGiftCarrierPrices';
import { timeoutDefaultValue } from '@gik/core/constants';
import type { Product, ProductCheckoutType } from '@gik/core/models/gik/Product';
import { CheckoutType } from '@gik/core/models/gik/Product';
import { useEnvStore } from '@gik/core/store/EnvStore';
import { useUserStore } from '@gik/core/store/UserStore';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { fieldHasError } from '@gik/core/utils/form';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import { storage } from '@gik/core/utils/Storage';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import i18n from '@gik/i18n';
import WishlistIcon from '@gik/shop/assets/wishlist.svg';
import { Button } from '@gik/ui/Button';
import type { ButtonElement } from '@gik/ui/Button/ButtonProps';
import { Checkbox } from '@gik/ui/Checkbox';
import type { FormProps, ValidationError, ValidationErrors } from '@gik/ui/Form';
import { Form, FormGroup } from '@gik/ui/Form';
import { Hint } from '@gik/ui/Hint';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { Input } from '@gik/ui/Input';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { RadioSelect } from '@gik/ui/Radio';
import { Select } from '@gik/ui/Select';
import { TextArea } from '@gik/ui/TextArea';
import { UI } from '@gik/ui/UIManager';
import CalendarIcon from '@heroicons/react/outline/CalendarIcon';
import EyeOffIcon from '@heroicons/react/solid/EyeOffIcon';
import loadable from '@loadable/component';
import type { Source, StripeCardElement } from '@stripe/stripe-js';
import type { FormApi, SubmissionErrors } from 'final-form';
import type { FormikValues } from 'formik/dist/types';
import React from 'react';
import { scroller } from 'react-scroll';
import useLatest from 'react-use/lib/useLatest';
import { setTimeout } from 'timers';
import { v4 as uuidv4 } from 'uuid';
import type { BillingDetails, CartItem, ShippingDetails } from '../../types';
import { PerfectgiftCarrierTypes } from '../../types';
import type { ICheckoutFormModalContext } from '../CheckoutForm/CheckoutFormModalContext';
import { CheckoutFormModalContext } from '../CheckoutForm/CheckoutFormModalContext';
import type { CartErrorsType } from '../ProductList/ProductList';
import { ProductList } from '../ProductList/ProductList';
import TipModal from '../TipModal/TipModal';
import { useIdempotencyKeysStore } from '@gik/core/store/IdempotencyKeysStore';
import { Debug } from '@gik/core/utils/asPlaceholderReactNode';
import { useInkind } from '@gik/api/inkinds/inkind';

const Format = loadable.lib(() => import('@gik/core/utils/format'));

export type CartError = {
  id: string;
  message: string;
};

export interface OrderSummaryProps extends FormProps {
  /**
   * Render Shipping information in order summary
   */
  shipping?: boolean;

  /**
   * Allow a shipping option to be selected (standard, fast delivery)
   */
  hasShipping?: boolean;

  /**
   * Allow Pg shipping option to be selected
   */
  hasPGShipping?: boolean;

  shippingDetails?: ShippingDetails;
  billing?: BillingDetails;
  cart?: CartItem[];
  claimErrors?: ClaimConflictErrorDetails[];
  carrier?: CardCarrierFormValues;
  inkindRouteId: string;
  initialValues?: OrderSummaryValues;

  billingInitialValues?: Partial<BillingFormValues>;
  productIds?: number[];
  creditCardLast4?: string;
  setCardElement?(cardElement: StripeCardElement): void;
  /** Called when form validation succeeds but either stripe token generation or address validation fails */
  // TODO: fix typing
  onSubmitBilling?: (values: BillingFormValues) => void | Promise<void>;
  onBeforeSubmitBilling?(): void;
  onBillingSubmitFail?();
  onBillingValidationFail?();

  onBillingAfterSubmit?: (
    values: object,
    form: FormApi<object, object>,
    source: Source
  ) => void | SubmissionErrors | Promise<SubmissionErrors>;

  setIsOrderSummaryFormLoading?(value: boolean): void;
  onTipChange?(tipPercentage: number, tipAmount: number): void;
  onOpenTermsOfService?(): void;
  onOpenPrivacyPolicy?(): void;
  onCarrierChanged?(cartItem: CartItem): void;
  onProductTypeChanged?(cartItem: CartItem): void;
  onClearSuggestions?(): void;
  buttons?: (isFormValid: boolean, cartHasError: boolean) => React.ReactNode;
  buttonsPortal?: HTMLElement;
  errorMessage?: string;
  stripeErrorMessage?: string;
  addFromCalendar?: boolean;
  addFromWishlist?: boolean;
  addToOrder?: boolean;
  buyForSomeoneElse?: boolean;
  privateClaim?: boolean;
  onClickFirstNameField?(): void;
  onExpandBillingForm?(): void;
  showPromoInput?: boolean;
  onSuccessfulPromo?(promoCodeId?: string): void;
  onStripeFieldChange?: () => void;
}

export interface OrderSummaryValues {
  tipPercentage?: number;
  tipAmount?: number;
  total?: number; // for verification against backend only
  messageToRecipient?: string;
  shippingOptionName?: string; // e.g. "Standard (5-7 business days)". id cannot be used as it is always "flat_rate"
  shippingValue?: number; // user for PG shipping
  subscribeToNewsletter?: boolean;
  deliveryMethod?: string;
  privateClaim?: boolean;
}

export interface OrderSummaryErrors<ValuesType extends FormikValues> extends ValidationErrors<ValuesType> {
  tipPercentage?: ValidationError;
  shippingOptionName?: ValidationError;
  messageToRecipient?: ValidationError;
}

function OrderSummaryComp({
  className,
  cart,
  carrier,
  initialValues,
  billingInitialValues,
  productIds,
  creditCardLast4,
  setCardElement,
  onSubmitBilling,
  onBeforeSubmitBilling,
  onBillingSubmitFail,
  onBillingValidationFail,
  onBillingAfterSubmit,
  inkindRouteId,
  shippingDetails,
  hasShipping,
  hasPGShipping: _hasPHShipping,
  billing,
  claimErrors: cartErrors,
  setIsOrderSummaryFormLoading,
  onChange,
  onTipChange,
  onSubmit,
  onOpenTermsOfService,
  onOpenPrivacyPolicy,
  onCarrierChanged,
  onProductTypeChanged,
  onClearSuggestions,
  buttons,
  buttonsPortal,
  shipping: _shipping,
  errorMessage,
  stripeErrorMessage,
  addFromCalendar,
  addFromWishlist,
  addToOrder,
  buyForSomeoneElse,
  privateClaim,
  onClickFirstNameField,
  onExpandBillingForm,
  showPromoInput = false,
  onSuccessfulPromo,
  onStripeFieldChange,
  ...otherProps
}: OrderSummaryProps): React.ReactElement {
  const bem = useBemCN('order-summary');

  const {
    isSubmitted,
    isSubmitting,
    mainCart,
    setMainCart,
    anonymous,
    selectedShippingOptionName,
    setSelectedShippingOptionName,
  } = React.useContext<ICheckoutFormModalContext>(CheckoutFormModalContext);
  const mainCartLatest = useLatest(mainCart);

  const [shouldRerender, setShouldRerender] = React.useState<boolean>(false);
  const [isCartReady, setIsCartReady] = React.useState<boolean>(true);
  const [cartHasError, setCartHasError] = React.useState<boolean>(false);
  const isCartReadyLatest = useLatest(isCartReady);
  const signinNeeded = React.useRef<boolean>(false);
  const { data: inkindPage } = useInkind(inkindRouteId);

  const { messageToRecipient, setMessageToRecipient } =
    React.useContext<ICheckoutFormModalContext>(CheckoutFormModalContext);

  const [_initialValues, _setInitialValues] = React.useState<OrderSummaryValues>(initialValues);

  const setCartStore = useCheckoutStore(state => state.setCart);

  const totalPhysicalCardsInCart = React.useMemo(() => {
    return mainCart?.filter(item => item?.productType === 'physical').length;
  }, [mainCart]);

  const totalDigitalCardsInCart = React.useMemo(() => {
    return mainCart?.filter(item => item?.productType !== 'physical').length;
  }, [mainCart]);

  const totalGiftCardsInCart = React.useMemo(() => {
    return mainCart?.filter(
      item =>
        item?.checkoutType === CheckoutType.TangoCard ||
        item?.checkoutType === CheckoutType.Perfectgift ||
        item?.checkoutType === CheckoutType.GiftyaPlatform
    ).length;
  }, [mainCart]);

  const shipping = React.useMemo(
    () => _shipping || totalPhysicalCardsInCart > 0,
    [_shipping, totalPhysicalCardsInCart]
  );
  const hasPGShipping = React.useMemo(
    () => _hasPHShipping || totalPhysicalCardsInCart > 0,
    [_hasPHShipping, totalPhysicalCardsInCart]
  );
  const { greetingCardCarrierPrice, trifoldWithEnvelopeCarrierPrice } = usePerfectGiftCarrierPrices();

  const checkoutType: ProductCheckoutType = React.useMemo(() => mainCart?.[0]?.checkoutType, [mainCart]);
  const isDonation = React.useMemo(() => checkoutType === CheckoutType.Donation, [checkoutType]);
  const calculateTaxes = hasShipping; // || checkoutType === 'gik-premium'; // TODO: other checkout types may also have tax

  const selectedShippingOptionNameLatest = useLatest(selectedShippingOptionName);
  const orderTaxCalculationPayload: Partial<CheckoutFormPayload> = React.useMemo(
    () => ({
      products: mainCart,
      shipping: {
        ...shippingDetails,
        shippingOptionName: selectedShippingOptionName,
      },
      billing,
    }),
    [billing, mainCart, shippingDetails, selectedShippingOptionName]
  );

  const shippingMethodKey = React.useMemo(
    () => (hasShipping && !hasPGShipping ? 'WooCommerce' : hasPGShipping ? 'Perfectgift' : null),
    [hasPGShipping, hasShipping]
  );
  const shippingMethods = useShippingMethods(shippingMethodKey, mainCart);
  const firstShipppingMethodId = React.useMemo(() => shippingMethods?.[0]?.uuid, [shippingMethods]);

  const orderTaxCalulation = useOrderTaxCalculation(calculateTaxes, orderTaxCalculationPayload);
  const selectedShippingOption = React.useMemo(
    () =>
      (hasShipping && orderTaxCalulation?.shipping.find(option => option.name === selectedShippingOptionName)) ||
      (hasPGShipping &&
        shippingMethods
          ?.map(sm => ({ ...sm, subtotal: sm?.settings?.cost.value, subtotal_tax: '0' }))
          .find(sm => sm.uuid === selectedShippingOptionName)),
    [hasPGShipping, hasShipping, orderTaxCalulation?.shipping, selectedShippingOptionName, shippingMethods]
  );

  const shippingRate = React.useMemo(
    () => parseFloat(selectedShippingOption?.subtotal || '0'),
    [selectedShippingOption]
  );
  const shippingTax = React.useMemo(
    () => parseFloat(selectedShippingOption?.subtotal_tax || '0'),
    [selectedShippingOption]
  );
  const productTaxes = React.useMemo(
    () => parseFloat(orderTaxCalulation?.products.total.subtotal_tax || '0'),
    [orderTaxCalulation]
  );
  const totalTax = React.useMemo(() => shippingTax + productTaxes, [productTaxes, shippingTax]);
  const initialPercentage = React.useMemo(() => {
    if (_initialValues?.tipPercentage !== undefined) return _initialValues?.tipPercentage;
    return isDonation ? 0 : 10;
  }, [_initialValues?.tipPercentage, isDonation]);

  const [tipPercentage, setTipPercentage] = React.useState(initialPercentage);
  const [tipAmount, setTipAmount] = React.useState(_initialValues?.tipAmount);
  const [tipOpen, setTipOpen] = React.useState(false);

  const carrierPrice = React.useMemo(
    () =>
      totalPhysicalCardsInCart > 0 && carrier?.carrierType === PerfectgiftCarrierTypes.GREETING_CARD
        ? greetingCardCarrierPrice
        : carrier?.carrierType === PerfectgiftCarrierTypes.TRIFOLD_WITH_ENVELOPE
          ? trifoldWithEnvelopeCarrierPrice
          : 0,
    [carrier, greetingCardCarrierPrice, totalPhysicalCardsInCart, trifoldWithEnvelopeCarrierPrice]
  );

  const subtotal = React.useMemo(() => {
    let subtotal = 0;
    // const mainCart = mainCartLatest.current;
    mainCart?.forEach(order => {
      subtotal += order?.price || 0;
    });
    const amount = Math.round((subtotal + carrierPrice) * (tipPercentage / 100));
    setTipAmount(amount);
    return subtotal;
    // setSubTotal(subTotal);
  }, [carrierPrice, mainCart, tipPercentage]);

  const tipValue = React.useMemo(
    () => Math.round((tipPercentage / 100) * (subtotal + carrierPrice)),
    [carrierPrice, subtotal, tipPercentage]
  );

  // calculate total to 2 decimal places (NB: also passed to backend for verification)

  const total = React.useMemo(
    () =>
      parseFloat(
        (
          subtotal +
          greetingCardCarrierPrice * totalPhysicalCardsInCart +
          shippingRate * totalPhysicalCardsInCart +
          totalTax +
          tipValue
        ).toFixed(2)
      ),
    [greetingCardCarrierPrice, shippingRate, subtotal, tipValue, totalPhysicalCardsInCart, totalTax]
  );

  const isLoading = React.useMemo(
    () =>
      calculateTaxes &&
      ((!hasShipping && !orderTaxCalulation) ||
        (hasShipping && (!shippingMethods || (selectedShippingOptionName && !selectedShippingOption)))),
    [
      calculateTaxes,
      hasShipping,
      orderTaxCalulation,
      selectedShippingOption,
      selectedShippingOptionName,
      shippingMethods,
    ]
  );
  const messageToRecipientMaxLength = React.useMemo(() => (checkoutType === 'giftbox' ? 220 : 1000), [checkoutType]);

  const userId = useUserStore(state => state.id);
  const shouldRenderLoading = React.useMemo(
    () => isLoading || (totalPhysicalCardsInCart > 0 && !selectedShippingOptionName) || shouldRerender,
    [isLoading, selectedShippingOptionName, shouldRerender, totalPhysicalCardsInCart]
  );

  const { frontendTransactionId } = useIdempotencyKeysStore(state => ({
    frontendTransactionId: state.frontendTransactionId,
  }));

  const handleTipOpen = React.useCallback(() => {
    setTipOpen(true);
  }, []);

  const handleTipSave = React.useCallback(
    (percentage: number, amount: number) => {
      setTipPercentage(percentage);
      setTipAmount(amount);
      setTipOpen(false);
      if (onTipChange) onTipChange(percentage, amount);
    },
    [onTipChange]
  );

  const handleTipInit = React.useCallback(
    (percentage: number) => {
      setTipPercentage(percentage);
      const amount = Math.round((subtotal + carrierPrice) * (percentage / 100));
      setTipAmount(amount);
    },
    [carrierPrice, subtotal]
  );

  const handleTipClose = React.useCallback(() => {
    setTipOpen(false);
  }, []);

  const handleOnChange = React.useCallback(
    (values: OrderSummaryValues): Promise<void> | void => {
      if (
        (totalPhysicalCardsInCart > 0 && !values.shippingOptionName) ||
        shouldRenderLoading ||
        !isCartReadyLatest.current
      )
        return;
      values.tipPercentage = tipPercentage;
      values.tipAmount = tipAmount;
      setSelectedShippingOptionName(values.shippingOptionName);

      // store message to recipient in localstorage per inkind for 48 hours
      // the message should be reused for other products on the same inkind page
      if (typeof values.messageToRecipient === 'string') {
        storage.setWithExpiry(`message-to-recipient-${inkindRouteId}`, values.messageToRecipient || '', 3600 * 48);
        setMessageToRecipient(values.messageToRecipient);
      }

      if (onChange) onChange(values);
    },
    [
      totalPhysicalCardsInCart,
      shouldRenderLoading,
      isCartReadyLatest,
      tipPercentage,
      tipAmount,
      setSelectedShippingOptionName,
      inkindRouteId,
      setMessageToRecipient,
      onChange,
    ]
  );

  const handleOnSubmit = React.useCallback(
    (values: OrderSummaryValues, form: FormApi<object, object>): Promise<void> | void => {
      // TODO: possible location where the total and tip values are set to 0
      values.tipPercentage = tipPercentage;
      values.tipAmount = tipAmount;
      values.total = total;

      if (hasPGShipping)
        values.shippingValue = parseFloat(
          shippingMethods?.find(method => method.uuid === values.shippingOptionName)?.settings.cost.value
        );

      if (onSubmit) onSubmit(values, form);
    },
    [tipPercentage, tipAmount, total, hasPGShipping, shippingMethods, onSubmit]
  );

  // TODO: consider using a schema (See Manual.story.tsx) so we do not need to add custom validation
  function customValidateForm(values: OrderSummaryValues): OrderSummaryErrors<OrderSummaryValues> {
    const errors: OrderSummaryErrors<OrderSummaryValues> = {};

    if (!values.messageToRecipient && checkoutType === 'giftbox') {
      errors.messageToRecipient = { message: i18n.t('validation.required') };
    }

    if (values.messageToRecipient && values.messageToRecipient.length > messageToRecipientMaxLength) {
      errors.messageToRecipient = { message: i18n.t('validation.maxLength', { count: messageToRecipientMaxLength }) };
    }

    if (!values.shippingOptionName && (hasShipping || hasPGShipping)) {
      errors.shippingOptionName = { message: i18n.t('validation.required') };
    }

    return errors;
  }

  React.useEffect(() => {
    if (setIsOrderSummaryFormLoading) {
      setIsOrderSummaryFormLoading(isLoading);
    }
  }, [isLoading, setIsOrderSummaryFormLoading]);

  const handleOpenTermsOfService = React.useCallback(
    (ev: React.MouseEvent<ButtonElement>) => {
      ev.stopPropagation();
      ev.preventDefault();
      onOpenTermsOfService?.();
    },
    [onOpenTermsOfService]
  );

  const handleOpenPrivacyPolicy = React.useCallback(
    (ev: React.MouseEvent<ButtonElement>) => {
      ev.stopPropagation();
      ev.preventDefault();
      onOpenPrivacyPolicy?.();
    },
    [onOpenPrivacyPolicy]
  );

  const isMessageToRecipientFieldVisible = React.useCallback(
    function isMessageToRecipientFieldVisible(): boolean {
      if (
        checkoutType === CheckoutType.Perfectgift ||
        checkoutType === CheckoutType.GiftyaPlatform ||
        checkoutType == CheckoutType.TangoCard
      ) {
        return totalDigitalCardsInCart > 0;
      }
      return checkoutType !== CheckoutType.GikPremium && checkoutType !== CheckoutType.Donation;
    },
    [checkoutType, totalDigitalCardsInCart]
  );

  const handleAddToOrder = React.useCallback(
    (data: CheckoutFormValues, addedFrom?: string) => {
      // set the carrier data on the cart item
      data.cart[0].carrier = data.carrier;
      // const mainCart = mainCartLatest.current;

      const shippingName = selectedShippingOptionName;

      // setIsCartReady(false);

      let eventName = AnalyticsEvents.AddFromWishlistLoadCheckout;
      if (addedFrom === 'calendar') {
        eventName = AnalyticsEvents.AddFromCalandarLoadCheckout;
      }

      Analytics.fireEvent(eventName, {
        userId,
        inkindRouteId,
        productId: data.cart[0].productId.toString(),
        productSlug: data.cart[0].productSlug,
        price: data.cart[0].price.toString(),
        anonymous: JSON.stringify(data.cart[0].anonymous),
      });

      if (!data.cart[0].id) {
        data.cart[0].id = uuidv4();
      }

      const newCart = mainCart ? mainCart.concat([]).concat(data.cart) : data.cart;
      setMainCart(newCart);
      if (!addToOrder) setCartStore(newCart);
      // forceRerender();

      setTimeout(() => {
        setSelectedShippingOptionName(shippingName);
        // setIsCartReady(true);
        // forceRerender();
        if (signinNeeded.current) {
          refillBillingAddressForm();
          signinNeeded.current = false;
        }
      }, 500);

      UI.notify('Item Added');
    },
    [
      mainCart,
      selectedShippingOptionName,
      userId,
      inkindRouteId,
      setMainCart,
      addToOrder,
      setCartStore,
      setSelectedShippingOptionName,
      signinNeeded,
    ]
  );

  const handleEditCartItem = React.useCallback(
    (data: CheckoutFormValues, entry?: ICalendarEntry, event?: ICalendarEvent) => {
      const newCartItem = data.cart?.[0];

      if (!newCartItem) return;

      const newCart = mainCart.concat([]);
      const matchingCartItem = newCart.find(item => item.id === newCartItem.id);

      if (matchingCartItem) {
        matchingCartItem.price = newCartItem.price;
        matchingCartItem.anonymous = newCartItem.anonymous;
        matchingCartItem.carrier = newCartItem.carrier;
        matchingCartItem.customMessage = newCartItem.customMessage;
        matchingCartItem.nameOnCard = newCartItem.nameOnCard;

        setMainCart(newCart);
        if (!addToOrder) setCartStore(newCart);
      }
    },
    [mainCart, setMainCart, setCartStore, addToOrder]
  );

  const handleAddFromWishlist = React.useCallback(() => {
    const recipientName = null;
    // const customMessage = mainCart?.[0]?.customMessage;
    const expiryMonth = mainCart?.[0]?.expiryMonth;
    const faceplate = mainCart?.[0]?.faceplate;

    Analytics.fireEvent(AnalyticsEvents.AddFromWishlistOpen, { userId, inkindRouteId });

    openAddToCheckoutModal({
      addToOrder: true,
      anonymousOverride: anonymous,
      selectFromWishlist: true,
      onAddToOrder: (data: CheckoutFormValues) => handleAddToOrder(data, 'wishlist'),
      recipientName,
      // customMessage,
      expiryMonth,
      faceplate,
      inkindRouteId,
      isOpenLoop: false,
      formProps: {
        id: 'add-to-checkout',
        initialData: {},
        inkindRouteId,
        initiatedOn: 'add-from-wishlist',
      },
    });
  }, [mainCart, userId, inkindRouteId, anonymous, handleAddToOrder]);

  const handleAddFromCalendar = React.useCallback(async () => {
    // user must be logged in for this
    if (!userId) {
      const result = await auth.signin({
        callout: SignInFlowCalloutBlock.CALENDAR_CLAIM,
        copyVariant: SignInFlowContentCopyVariant.INKIND_PAGE,
        routeId: inkindRouteId,
      });

      if (!result) return;
      signinNeeded.current = true;
    }

    const mainCart = mainCartLatest.current;

    const recipientName = null;
    // const customMessage = mainCart?.[0]?.customMessage;
    const expiryMonth = mainCart?.[0]?.expiryMonth;
    const faceplate = mainCart?.[0]?.faceplate;

    Analytics.fireEvent(AnalyticsEvents.AddFromCalendarOpen, { userId, inkindRouteId });

    openAddToCheckoutModal({
      addToOrder: true,
      anonymousOverride: anonymous,
      selectFromCalendar: true,
      onAddToOrder: (data: CheckoutFormValues) => handleAddToOrder(data, 'calendar'),
      recipientName,
      // customMessage,
      expiryMonth,
      faceplate,
      inkindRouteId,
      isOpenLoop: false,
      formProps: {
        id: 'add-to-checkout',
        currentCart: mainCart.concat([]),
        initialData: {},
        inkindRouteId,
        initiatedOn: 'add-from-calendar',
        privateClaim,
      },
    });
  }, [userId, mainCartLatest, inkindRouteId, anonymous, privateClaim, handleAddToOrder]);

  const hasCartItems = mainCart?.length > 0;

  const handlePriceChange = React.useCallback(
    (cartItem: CartItem, product: Product, newPrice: number, newVariationId: number, errors: CartErrorsType[]) => {
      setCartHasError(false);

      if (errors.length) {
        // console.log('cartHasError', errors);
        setCartHasError(true);
        return;
      }

      const newCart = mainCart.concat([]);
      const matchingCartItem = newCart.find(item => item.id === cartItem.id);

      if (matchingCartItem) {
        matchingCartItem.price = newPrice;
        matchingCartItem.variationId = newVariationId;
        setMainCart(newCart);
        if (!addToOrder) setCartStore(newCart);
      }
    },
    [addToOrder, mainCart, setCartStore, setMainCart]
  );

  const handleRemove = React.useCallback(
    (cartItem: CartItem) => {
      (async () => {
        // confirm removal
        const confirmResponse = await UI.confirm('Are you sure you want to remove this item from your order?', {
          title: 'Confirm Delete',
          okButtonProps: { variant: 'danger' },
          okText: 'Delete',
        });
        if (!confirmResponse) return;

        const mainCart = mainCartLatest.current;

        // if the item to be removed is the first item in the cart the we also need to update other values in the main checkout form accordingly
        // such as the carrier details and the product type
        const cartIndex = mainCart.findIndex(item => item.id === cartItem.id);
        const oldCartItem = mainCart[cartIndex];

        const newCart = mainCart.concat([]).filter(item => item.id !== cartItem.id);
        setMainCart(newCart);
        if (!addToOrder) setCartStore(newCart);

        Analytics.fireEvent(AnalyticsEvents.LoadFinishCheckoutRemovedGiftCard, {
          userId,
          inkindRouteId,
          productId: oldCartItem.productId.toString(),
          productSlug: oldCartItem.productSlug,
          price: oldCartItem.price.toString(),
        });

        setTimeout(() => {
          if (cartIndex === 0) {
            onCarrierChanged(newCart[cartIndex]);

            // figure out if the first item changed from a digital item to physical or vise verca
            if (oldCartItem?.productType !== newCart[cartIndex]?.productType) {
              onProductTypeChanged(newCart[cartIndex]);
              forceRerender();
            }
          }

          UI.notify('Item Removed');
        }, timeoutDefaultValue);
      })();
    },
    [
      mainCartLatest,
      setMainCart,
      addToOrder,
      setCartStore,
      userId,
      inkindRouteId,
      onCarrierChanged,
      onProductTypeChanged,
    ]
  );

  // const handleProductClick = React.useCallback(
  //   (cartItem: CartItem) => {
  //     const recipientName = null;
  //     const customMessage = mainCart?.[0]?.customMessage;
  //     const expiryMonth = mainCart?.[0]?.expiryMonth;
  //     const faceplate = mainCart?.[0]?.faceplate;

  //     if (cartItem.createClaimRequest) {
  //       // cart item is a calendar claim
  //       openEditCartItemModal({
  //         addToOrder: true,
  //         editMode: true,
  //         anonymousOverride: anonymous,
  //         selectFromCalendar: true,
  //         onAddtoOrder: handleAddToOrder,
  //         recipientName,
  //         expiryMonth,
  //         faceplate,
  //         inkindRouteId,
  //         isOpenLoop: false,
  //         formProps: {
  //           id: 'edit-cart-item',
  //           initialCreateClaimRequest: cartItem.createClaimRequest,
  //           initialClaimEntry: cartItem.claimEntry,
  //           cart: [cartItem],
  //           currentCart: mainCart.concat([]),
  //           initialData: {},
  //           inkindRouteId,
  //           initiatedOn: 'edit-cart-item',
  //         },
  //       });

  //       return;
  //     }

  //     // cart item is a wishlist item
  //     openEditCartItemModal({
  //       addToOrder: true,
  //       editMode: true,
  //       anonymousOverride: anonymous,
  //       selectFromWishlist: true,
  //       onAddtoOrder: handleEditCartItem,
  //       recipientName,
  //       // customMessage,
  //       expiryMonth,
  //       faceplate,
  //       inkindRouteId,
  //       isOpenLoop: false,
  //       formProps: {
  //         cart: [cartItem],
  //         currentCart: mainCart.concat([]),
  //         initialData: {
  //           carrier: cartItem.carrier,
  //         },
  //         inkindRouteId,
  //         initiatedOn: 'add-from-calendar',
  //       },
  //     });
  //   },
  //   [anonymous, handleAddToOrder, handleEditCartItem, inkindRouteId, mainCart]
  // );

  React.useEffect(() => {
    if (totalPhysicalCardsInCart > 0 && !firstShipppingMethodId) return;

    if (!selectedShippingOptionNameLatest.current) {
      setSelectedShippingOptionName(firstShipppingMethodId);
      // forceRerender();
    }

    setIsCartReady(false);
    _setInitialValues({
      ...initialValues,
      messageToRecipient,
      shippingOptionName: selectedShippingOptionNameLatest.current || firstShipppingMethodId,
    });

    setTimeout(() => {
      setIsCartReady(true);
    }, 100);
  }, [
    firstShipppingMethodId,
    initialValues,
    messageToRecipient,
    selectedShippingOptionNameLatest,
    setSelectedShippingOptionName,
    totalPhysicalCardsInCart,
  ]);

  const [previousErrorMessage, setPreviousErrorMessage] = React.useState(null);
  const [previousStripeErrorMessage, setPreviousStripeErrorMessage] = React.useState(null);

  React.useEffect(() => {
    if (
      errorMessage &&
      errorMessage !== previousErrorMessage &&
      !errorMessage.toLowerCase().startsWith('the provided source has already been used'.toLowerCase()) &&
      !errorMessage.toLowerCase().includes('payment_intent_incompatible_payment_method'.toLowerCase())
    ) {
      setPreviousErrorMessage(errorMessage);
      UI.notifyError(errorMessage);
    }
  }, [errorMessage, previousErrorMessage]);

  React.useEffect(() => {
    if (
      stripeErrorMessage &&
      stripeErrorMessage !== previousStripeErrorMessage &&
      !stripeErrorMessage.toLowerCase().startsWith('the provided source has already been used'.toLowerCase()) &&
      !stripeErrorMessage.toLowerCase().includes('payment_intent_incompatible_payment_method'.toLowerCase())
    ) {
      setPreviousStripeErrorMessage(stripeErrorMessage);
      UI.notifyError(stripeErrorMessage);
    }
  }, [stripeErrorMessage, previousStripeErrorMessage]);

  const errorContainerClassName = 'gik-order-summary__error-container';
  React.useEffect(() => {
    if (errorMessage || stripeErrorMessage) {
      setTimeout(() => {
        scroller.scrollTo(errorContainerClassName, {
          duration: 300,
          offset: -16,
          smooth: true,
          container:
            document.getElementById(checkoutFormContentId) ?? document.getElementById(calendarEventClaimFormContentId),
        });
      }, timeoutDefaultValue);
    }
  }, [errorMessage, stripeErrorMessage]);

  function forceRerender() {
    setShouldRerender(true);
    setTimeout(() => {
      setShouldRerender(false);
    }, timeoutDefaultValue);
  }

  const handleRemoveClaim = React.useCallback(
    (cartItem: CartItem) => {
      // remove the cart item
      handleRemove(cartItem);

      Analytics.fireEvent(AnalyticsEvents.ClaimConflictsRemoveGiftCard, {
        userId,
        inkindRouteId,
        cartItemId: cartItem.id,
        cartItemName: cartItem.name,
        cartItemProductId: cartItem.productId.toString(),
        cartItemProductSlug: cartItem.productSlug,
      });
    },
    [handleRemove, inkindRouteId, userId]
  );

  const { data: siteSettings } = useSiteSettings();

  const hideAddMoreButtonsEnv = useEnvStore(state => state.CHECKOUT_HIDE_ADD_MORE_BUTTONS);
  const hideAddMoreButtons = !(siteSettings?.multipleItemCheckout === 'true' && hideAddMoreButtonsEnv !== 'true');

  if (shouldRenderLoading) return <LoadingSpinner center />;

  const showShippingAsRadio = shippingMethods?.filter(option => !!option)?.length <= 10;

  const tacLink = (
    <Button
      variant="default-extra-dark-link-solid"
      onClickCapture={(ev: React.MouseEvent<HTMLAnchorElement>) => {
        // prevent click when disabled
        // FIXME: this should be handled by the Button component
        if (isSubmitted || isSubmitting) return;

        handleOpenTermsOfService(ev);
      }}
      preventClickWhenDisabled
      disabled={isSubmitted || isSubmitting || !hasCartItems}
    >
      terms and conditions
    </Button>
  );

  const ppLink = (
    <Button
      variant="default-extra-dark-link-solid"
      onClickCapture={(ev: React.MouseEvent<HTMLAnchorElement>) => {
        // prevent click when disabled
        // FIXME: this should be handled by the Button component
        if (isSubmitted || isSubmitting) return;

        handleOpenPrivacyPolicy(ev);
      }}
      preventClickWhenDisabled
      disabled={isSubmitted || isSubmitting || !hasCartItems}
    >
      privacy policy
    </Button>
  );

  return (
    <Format fallback={'-'}>
      {({ formatCurrency }) => {
        return (
          <>
            <Form
              ref={useCheckoutFormModalStore.getState().orderSummaryFormRef}
              onSubmit={handleOnSubmit}
              onChange={handleOnChange}
              initialValues={_initialValues}
              vertical
              validate={customValidateForm}
              validateOnBlur={false}
              // restoreAfterUpdate
              {...bem(null, null, className)}
              {...otherProps}
              render={form => {
                const { isSubmitting, isValid, values } = form;

                const shippingName = (values as OrderSummaryValues).shippingOptionName;
                const shippingMethod = shippingMethods?.find(method => method.uuid === shippingName);
                const shippingCost = parseFloat(shippingMethod?.settings?.cost.value);

                return (
                  <div className="gik-order-summary">
                    <Debug data={frontendTransactionId} />
                    <Debug
                      data={
                        'isWalletActive:' +
                        inkindPage?.isWalletActive +
                        ' | isWalletFeatureActive:' +
                        inkindPage?.isWalletFeatureActive
                      }
                    />
                    {isMessageToRecipientFieldVisible() && (
                      <FormGroup
                        {...fieldHasError(form, 'messageToRecipient')}
                        label="Message to Recipient (optional)"
                        vertical
                        fieldName="messageToRecipient"
                      >
                        <TextArea
                          value={form.values['messageToRecipient']}
                          onValueChange={v => form.setFieldValue('messageToRecipient', v)}
                          autoGrow
                          rows={2}
                          maxLength={messageToRecipientMaxLength}
                          disabled={isSubmitted || isSubmitting}
                          maxLengthDisplay
                        />
                      </FormGroup>
                    )}

                    {mainCart?.[0]?.checkoutType !== 'gik-premium' && (
                      <>
                        <div className="tw-flex tw-items-center tw-justify-between tw-mb-4 tw-border-b-2 tw-border-neutral-300">
                          <span className="gik-form-header">Your Order</span>
                          {anonymous && (
                            <div className="tw-flex tw-gap-2 tw-text-neutral-700">
                              <EyeOffIcon height={20} /> <span>Anonymous Gift</span>
                            </div>
                          )}
                        </div>

                        {/* <div {...bem('subtotal')}>
                        <span {...bem('subtotal-label')}>Subtotal</span>
                        <span {...bem('subtotal-value')}>{mainCart?.length} Gift Cards</span>
                      </div> */}
                      </>
                    )}

                    {/*<Button onClick={onClearSuggestions}>Clear suggestions</Button>*/}

                    <ProductList
                      cart={mainCart}
                      // errors={cartErrors}
                      shipping={shippingDetails}
                      inkindRouteId={inkindRouteId}
                      onRemove={handleRemove}
                      onPriceChange={handlePriceChange}
                      allowRemove={!buyForSomeoneElse && mainCart?.[0]?.checkoutType !== 'gik-premium'}
                      // onClick={handleProductClick} // handle edit cart item
                      onRemoveClaim={handleRemoveClaim}
                      showPromoInput={showPromoInput}
                      onSuccessfulPromo={onSuccessfulPromo}
                    />

                    {(addFromCalendar || addFromWishlist) && !hideAddMoreButtons && (
                      <div {...bem('add-more')}>
                        <span {...bem('add-more-title')}>Add another Gift Card from:</span>
                        <div {...bem('add-more-buttons')}>
                          {addFromCalendar && (
                            <Button
                              variant="secondary"
                              wide={!isMobile()}
                              prepend={<CalendarIcon />}
                              onClick={handleAddFromCalendar}
                            >
                              Calendar
                            </Button>
                          )}
                          {addFromWishlist && (
                            <Button
                              variant="secondary"
                              wide={!isMobile()}
                              prepend={<WishlistIcon />}
                              onClick={handleAddFromWishlist}
                            >
                              Wishlist
                            </Button>
                          )}
                        </div>
                      </div>
                    )}

                    <ul {...bem('summary-list', [{ disabled: !hasCartItems }])}>
                      <li>
                        <div {...bem('summary-line')}>
                          <label>
                            <strong>Subtotal</strong>
                          </label>
                          <span>{formatCurrency(subtotal)}</span>
                        </div>
                      </li>

                      {totalPhysicalCardsInCart > 0 && (
                        <li>
                          <div {...bem('summary-line')}>
                            <label>
                              <strong>Greeting Card{totalPhysicalCardsInCart > 1 ? 's' : ''} </strong>
                            </label>
                            <span className="tw-mr-1 tw-text-neutral-700 tw-flex-1 tw-justify-center tw-text-center">
                              {totalPhysicalCardsInCart}@{formatCurrency(greetingCardCarrierPrice)}
                            </span>
                            <div>{formatCurrency(greetingCardCarrierPrice * totalPhysicalCardsInCart)}</div>
                          </div>
                        </li>
                      )}
                      {/* {totalPhysicalCardsInCart > 0 &&
                      carrier?.carrierType === PerfectgiftCarrierTypes.TRIFOLD_WITH_ENVELOPE && (
                        <li>
                          <div {...bem('summary-line')}>
                            <label>
                              <strong>Trifold</strong>
                            </label>
                            <span>{formatCurrency(trifoldWithEnvelopeCarrierPrice)}</span>
                          </div>
                        </li>
                      )} */}
                      {shipping && shippingDetails && (
                        <>
                          <li className="nopadding">
                            <div {...bem('shipping-method', [{ radio: showShippingAsRadio }])}>
                              <div className="tw-flex-1 tw-flex tw-justify-between tw-my-2">
                                <label className="tw-mb-2">
                                  <strong>Shipping Method</strong>
                                </label>
                                <span>{formatCurrency(shippingCost * totalPhysicalCardsInCart)}</span>
                              </div>
                              <span {...bem('shipping-method-content')}>
                                {(hasShipping || hasPGShipping) && shippingMethods && (
                                  <>
                                    <FormGroup {...fieldHasError(form, 'shippingOptionName')}>
                                      {showShippingAsRadio ? (
                                        <RadioSelect
                                          value={form.values['shippingOptionName']}
                                          onChange={v => form.setFieldValue('shippingOptionName', v)}
                                          variant="primary"
                                          disabled={isSubmitted || isSubmitting}
                                          schema={shippingMethods.map(option => {
                                            return {
                                              label: `${option.title} - ${formatCurrency(
                                                parseFloat(option.settings.cost.value) * totalPhysicalCardsInCart
                                              )}`,
                                              value: option.uuid ?? option.title,
                                              variant: 'primary',
                                            };
                                          })}
                                        />
                                      ) : (
                                        <Select
                                          value={form.values['shippingOptionName']}
                                          onChange={v => form.setFieldValue('shippingOptionName', v)}
                                          options={shippingMethods.map(option => {
                                            const bracketIndex = option.title.indexOf('(');
                                            return {
                                              label:
                                                option.title +
                                                ' - ' +
                                                formatCurrency(
                                                  parseFloat(option.settings.cost.value) * totalPhysicalCardsInCart
                                                ),
                                              value: option.uuid ?? option.title,
                                              variant: 'primary',
                                            };
                                          })}
                                          className="tw-w-full"
                                        />
                                      )}
                                    </FormGroup>
                                  </>
                                )}
                              </span>
                            </div>
                            {/* <hr className="tw-my-2 tw-border-neutral-500" /> */}
                          </li>

                          {buyForSomeoneElse && (
                            <li className="tw-flex tw-gap-2 tw-flex-col">
                              <label>
                                <strong>Shipping Address</strong>
                              </label>
                              <span className="tw-flex-1">
                                <address>
                                  {!hasPGShipping && (
                                    <>
                                      {shippingDetails.firstName} {shippingDetails.lastName} <br />
                                    </>
                                  )}
                                  {hasPGShipping && (
                                    <>
                                      {carrier?.toName} <br />
                                    </>
                                  )}
                                  {shippingDetails.address1} <br />
                                  {shippingDetails.address2} <br />
                                  {shippingDetails.city}, {shippingDetails.state} {shippingDetails.postalCode} <br />
                                </address>
                              </span>
                            </li>
                          )}
                        </>
                      )}

                      {checkoutType !== 'donation' && (
                        <li>
                          <div {...bem('summary-line')}>
                            <label>
                              <strong>Give InKind Tip</strong>
                            </label>
                            <span>
                              {formatCurrency(tipValue)}&nbsp;
                              <Button
                                variant="primary-link"
                                onClick={handleTipOpen}
                                preventClickWhenDisabled
                                disabled={isSubmitted || isSubmitting || !hasCartItems}
                              >
                                change
                              </Button>
                              <FormGroup {...fieldHasError(form, 'tipPercentage')}>
                                <Input
                                  onChange={v => form.setFieldValue('tipPercentage', v)}
                                  type="hidden"
                                  value={tipValue + ''}
                                />
                              </FormGroup>
                              <TipModal
                                isOpen={tipOpen}
                                percentageValue={tipPercentage}
                                totalCheckoutPrice={subtotal + carrierPrice}
                                onSave={handleTipSave}
                                onClose={handleTipClose}
                                onInit={handleTipInit}
                              />
                            </span>
                          </div>
                          <Hint className="gik-datalist__help">
                            Your support of Give InKind helps us remain free when it&apos;s needed most.
                          </Hint>
                        </li>
                      )}
                      <li>
                        <div {...bem('summary-line')}>
                          <label>
                            <strong>Total</strong>
                          </label>
                          <strong>{formatCurrency(total)}</strong>
                        </div>
                      </li>
                    </ul>

                    {!userId && (
                      <FormGroup
                        {...fieldHasError(form, 'subscribeToNewsletter')}
                        fieldName="subscribeToNewsletter"
                        {...bem('field', [{ disabled: !hasCartItems }])}
                      >
                        <Checkbox
                          onValueChange={v => form.setFieldValue('subscribeToNewsletter', v)}
                          id="subscribe-to-newsletter"
                          checked={form.values['subscribeToNewsletter']}
                          variant="primary"
                          disabled={isSubmitted || isSubmitting || !hasCartItems}
                          label={<span>Update me on additional ideas for helping, resources, and new features</span>}
                        />
                      </FormGroup>
                    )}

                    {(errorMessage || stripeErrorMessage) && (
                      <div id={errorContainerClassName} className={errorContainerClassName}>
                        {stripeErrorMessage && (
                          <div className="gik-form__error">
                            <HTMLParser
                              allowFormattingTags={false}
                              rawHtml={
                                (stripeErrorMessage
                                  .toLowerCase()
                                  .startsWith('the provided source has already been used'.toLowerCase()) ||
                                  stripeErrorMessage
                                    .toLowerCase()
                                    .includes('payment_intent_incompatible_payment_method'.toLowerCase())) &&
                                previousStripeErrorMessage
                                  ? previousStripeErrorMessage
                                  : stripeErrorMessage
                              }
                            />
                          </div>
                        )}
                        {errorMessage && (
                          <div className="gik-form__error">
                            <HTMLParser
                              allowFormattingTags={false}
                              rawHtml={
                                (errorMessage
                                  .toLowerCase()
                                  .startsWith('the provided source has already been used'.toLowerCase()) ||
                                  errorMessage
                                    .toLowerCase()
                                    .includes('payment_intent_incompatible_payment_method'.toLowerCase())) &&
                                previousErrorMessage
                                  ? previousErrorMessage
                                  : errorMessage
                              }
                            />
                          </div>
                        )}
                      </div>
                    )}

                    {renderPortal?.(buttons && buttons?.(isValid, cartHasError), () => buttonsPortal)}
                  </div>
                );
              }}
            />
            <p {...bem('disclaimer')} id={'payment-container'}>
              By purchasing, you agree to the website {tacLink}, and {ppLink}.
            </p>

            <ExpressCheckout
              cart={cart}
              tip={tipAmount}
              shipping={shippingRate}
              total={total}
              billingInitialValues={billingInitialValues}
              productIds={productIds}
              creditCardLast4={creditCardLast4}
              setCardElement={setCardElement}
              onSubmit={onSubmitBilling}
              onBeforeSubmitPurchaseCard={onBeforeSubmitBilling}
              onBillingSubmitFail={onBillingSubmitFail}
              shippingDetails={shippingDetails}
              onBillingValidationFail={onBillingValidationFail}
              onBillingAfterSubmit={onBillingAfterSubmit}
              onClickFirstNameField={onClickFirstNameField}
              onExpandBillingForm={onExpandBillingForm}
              disabled={isSubmitted || isSubmitting || !hasCartItems}
              onStripeFieldChange={onStripeFieldChange}
            />

            {totalGiftCardsInCart > 0 && (
              <Hint>
                <p className="gik-order-summary__disclaimer">
                  <strong>U.S. purchases only.</strong> All Gift Card purchases are final and non-refundable. Gift Cards
                  are delivered 24 hours after purchase for security reasons. Charges will appear as Give InKind
                </p>
              </Hint>
            )}
            <div className={'tw-pb-40'} />
          </>
        );
      }}
    </Format>
  );
}

export const OrderSummary = withComponentErrorBoundary(OrderSummaryComp);
