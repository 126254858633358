export function nl2br(str: string, is_xhtml?: boolean) {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  const breakTag = is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export function fuzzysearch(needle: string, haystack: string, ignoreCase = true) {
  const hlen = haystack.length;
  const nlen = needle.length;

  let _needle = needle;
  let _haystack = haystack;
  if (ignoreCase) {
    _needle = _needle.toLocaleLowerCase();
    _haystack = _haystack.toLocaleLowerCase();
  }

  if (nlen > hlen) {
    return false;
  }
  if (nlen === hlen) {
    return _needle === _haystack;
  }
  outer: for (let i = 0, j = 0; i < nlen; i++) {
    const nch = _needle.charCodeAt(i);
    while (j < hlen) {
      if (_haystack.charCodeAt(j++) === nch) {
        continue outer;
      }
    }
    return false;
  }
  return true;
}

export const shortText = (text: string, letterCount: number = 5) => {
  if (!text) return text;
  if (text.length < letterCount * 2 + 2) return text;
  return text.substring(0, letterCount) + '...' + text.substring(text.length - letterCount, text.length);
};
