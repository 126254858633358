import { useQuery } from '@tanstack/react-query';
import { giftWalletAdminEndPoints as endPoints } from '@gik/platform-management-api-js';
import type {
  StrapiPagedDataSource,
  StrapiSearchParams,
  StrapiSingleDataSource,
} from '@gik/platform-management-api-js';
import { usePlatformManagementClient } from '../../../contexts';
import type { OrderLogEntity } from '@gik/platform-management-api-js';
import type { ReactQueryOptions } from '../../../types/react-query';
import { createPathWithSearchParams } from '../../../utils/createPathWithSearchParams';
import { useQueryDefaults } from '../../../client';

/**
 * List Order Logs
 */
export function useAdminOrderLogs(
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiPagedDataSource<OrderLogEntity>>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.orderLog.list(), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.orderLog.list({ params })).data,
  });
}

/**
 * Get Order Log by id
 */
export function useAdminOrderLog(
  id?: string,
  params?: Record<string, never>,
  options?: ReactQueryOptions<StrapiSingleDataSource<OrderLogEntity>>
) {
  const { client } = usePlatformManagementClient();
  const key = endPoints.orderLog.get(id);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    queryKey: [key],
    queryFn: async () =>
      (
        await client.admin.orderLog.get(id, {
          params,
        })
      ).data,
  });
}

/**
 * Get Order Log by id
 */
export function useAdminOrderLogByUuid(
  id?: string,
  params?: Record<string, never>,
  options?: ReactQueryOptions<OrderLogEntity>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.list(), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    queryKey: [key],
    queryFn: async () =>
      await client.admin.orderLog.getByUuid(id, {
        params,
      }),
  });
}

/**
 * Get Order Log by id
 */
export function useAdminOrderLogByOrderId(
  id?: string,
  params?: Record<string, never>,
  options?: ReactQueryOptions<OrderLogEntity>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.orderLog.getByOrderId(id), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    queryKey: [key],
    queryFn: async () =>
      await client.admin.orderLog.getByOrderId(id, {
        params,
      }),
  });
}
