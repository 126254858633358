import { logger } from '@gik/analytics/utils/logger';
import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import type { Product } from '@gik/core/models/gik/Product';
import type { Product as ModelsProduct } from '@gik/models/gik/Product';

import { CheckoutType } from '@gik/core/models/gik/Product';
import { openExternalLink } from '@gik/core/utils/browser';
import isInkindPageRecipientInfoComplete from '@gik/inkind-page/utils/isInkindPageRecipientInfoComplete';
import type { OLProductType } from '@gik/shop/components/products/ProductSection/ProductSection';
import { defaultToggleValue, getDefaultFaceplate } from '@gik/shop/hooks/useOpenLoop';
import { getPGOLExpirationMonth } from '@gik/shop/hooks/usePGOLExpirationMonth';
import { UI } from '@gik/ui/UIManager';
import { v4 as uuidv4 } from 'uuid';
import type { CartItem } from '../types';

/**
 * Determine if a product is a TangoCard product
 */
export function productIsTangoCard(product: Product) {
  return product.checkoutType === CheckoutType.TangoCard;
}

/**
 * Determine if a product is a gift card
 * either TC or PG
 */
export function productIsGiftcard(product: Product) {
  return (
    product.checkoutType === CheckoutType.TangoCard ||
    product.checkoutType === CheckoutType.Perfectgift ||
    product.checkoutType === CheckoutType.GiftyaPlatform
  );
}

/**
 * Determine if a product can be purchased in-site
 */
export function productHasIntegratedCheckout(product: Product) {
  return (
    product.checkoutType === CheckoutType.TangoCard ||
    product.checkoutType === CheckoutType.Perfectgift ||
    product.checkoutType === CheckoutType.Giftbox ||
    product.checkoutType === CheckoutType.VirtualProduct ||
    product.checkoutType === CheckoutType.GikPremium ||
    product.checkoutType === CheckoutType.GiftyaPlatform
  );
}
/**
 * Display product affiliate note
 */
export function productHasAffiliateRelationship(product: Product) {
  return (
    product.checkoutType === CheckoutType.TangoCard ||
    product.checkoutType === CheckoutType.Perfectgift ||
    product.checkoutType === CheckoutType.GiftyaPlatform ||
    product.checkoutType === CheckoutType.VirtualProduct
  );
}

/**
 * Show source info for affiliate product
 */
export function productIsAffiliate(product: Product) {
  return !productIsTangoCard(product) && !product.isInternalProduct;
}

export type PaymentMethod = 'stripe' | 'google-pay' | 'apple-pay';
/**
 * Translates a payment method name into a human friendly string
 * TODO: this should be translated through i18n
 */
export function parsePaymentMethodName(paymentMethod: PaymentMethod): string {
  switch (paymentMethod) {
    case 'stripe':
      return 'Credit Card (Stripe)';
    case 'google-pay':
      return 'Google Pay';
    case 'apple-pay':
      return 'Apple Pay';
    default:
      return 'Unknown';
  }
}

/**
 * Open an affiliate product link
 */
export function openNonD2CUrl(productNonD2CUrl: string) {
  openExternalLink(productNonD2CUrl);
}

/**
 * Triggered when a user tries to purchase a product from an affiliate link that does not exist
 */
export function alertNoNonD2CUrlAvailable() {
  logger.error('No non-d2c URL for product');
  UI.alert('Unfortunately this product cannot be purchased right now.');
}

export function createCartItemFromProduct(
  product: Product | ModelsProduct,
  inkindPage?: InkindPageAPIModel,
  isWishlist: boolean = false
): CartItem {
  if (!product) {
    return undefined;
  }
  const cartItem: CartItem = {
    id: uuidv4(),
    price: product.price > 0 ? product.price : undefined,
    productId: product.id,
    quantity: 1,
    name: product.name,
    checkoutType: product.checkoutType,
    groupedProductIds: product.groupedProductIds,
    productSlug: product.slug,
    isWishlist,
  };

  if (product.variations?.length === 1) {
    cartItem.variationId = product.variations[0].id;
  }

  if (product.isToggleable) {
    const productType: OLProductType = inkindPage
      ? isInkindPageRecipientInfoComplete(inkindPage)
        ? 'physical'
        : 'digital'
      : defaultToggleValue;
    const variation = product.variations.find(v =>
      v.metaData.find(m => m.key === 'pg_product_type' && m.value === productType)
    );

    cartItem.variationId = variation?.id;
    cartItem.expiryMonth = getPGOLExpirationMonth(product, defaultToggleValue);
    cartItem.faceplate = getDefaultFaceplate(product);
  }
  return cartItem;
}
