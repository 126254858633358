import bemBlock from '@gik/core/utils/bemBlock';
import type { UISize, UIVariant } from '@gik/ui/types';
import React from 'react';

export interface ISeparatorProps extends React.HTMLAttributes<HTMLHRElement> {
  variant?: UIVariant | 'danger' | 'success' | 'primary-dark' | 'primary-light' | 'neutral-300';
  type?: 'solid' | 'dashed' | 'dotted';
  thickness?: 'thin' | 'normal' | 'double' | '3x';
  size?: 'none' | UISize;
  className?: string;
  direction?: 'horizontal' | 'vertical';
}

const blockName = 'separator';

function SeparatorComp(
  {
    variant = 'default',
    type = 'solid',
    thickness = 'normal',
    size = 'base',
    direction = 'horizontal',
    style,
    className,
    children,
  }: React.PropsWithChildren<ISeparatorProps>,
  ref
) {
  const bem = bemBlock(blockName);
  const modifiers = [
    {
      [`variant-${variant}`]: variant,
    },
    {
      [`size-${size}`]: size,
    },
    {
      [`direction-${direction}`]: direction,
    },
    {
      [`type-${type}`]: type,
    },
    {
      [`thickness-${thickness}`]: thickness,
    },
    {
      'with-content': children !== undefined,
    },
  ];

  return (
    <>
      {children ? (
        <div ref={ref} className={bem(null, modifiers, className)} style={style}>
          {children}
        </div>
      ) : (
        <hr ref={ref} className={bem(null, modifiers, className)} style={style} />
      )}
    </>
  );
}

export const Separator = React.forwardRef(SeparatorComp);
