import { gikClassPrefix } from '@gik/core/constants';
import classnames from 'classnames';
import React from 'react';
import type { BaseBoneProps } from './BaseBone';

const blockName = `${gikClassPrefix}-div-bone`;

export interface DivBoneProps extends React.PropsWithChildren<BaseBoneProps> {}

export function DivBone({ className, children }: DivBoneProps) {
  const blockClasses = classnames([blockName, className || '']);

  return <div className={blockClasses}>{children}</div>;
}
