import type { GiftNoteEntity } from './GiftNote';
import type { StrapiEntity } from './Strapi';

export type GiftEntity = StrapiEntity & GiftEntityAttributes;

export type GiftEntityAttributes = {
  amount: number;
  balance: number;
  uuid: string;
  status: GiftStatus;
  orderId: string;
  customerId: string;
  purchasedFrom: string;
  purchasedAs?: string;
  messageToRecipient: string;
  lineItemId: string;
  sourceProductName: string; // perfectgift or giftya
  sourceType: string; // perfectgift or giftya
  jobId: number;
  sourceProductId: string;
  sourceVariationId: string;
  wcProductId: string;
  wcVariationId: string;
  shippingFee?: number;
  pgOrderId?: string;
  pgOrderTotal?: number;
  pgRetryCount?: number;
  pgOrderFulfillmentStatus?: string;
  pgOrderStatus?: string;
  gyGiftId?: string;
  gyEGiftId?: string;
  imageUrl?: string;
  supporterId?: string;
  isAnonymous: boolean;
  thankYouSent: boolean;
  redemptionMethods: string; //GiftyaRedemtionMethod[];
  redeemedAsMethod: GiftRedemptionMethod;
  markedAsUsed: boolean;
  clientRecipientId: string;
  merchantId: string;
  gyReferenceId: string;
  calendarClaim?: CalendarClaimDetails;
  physicalCardDetails?: PhysicalCardDetails;
  metadata?: Record<string, unknown>;
  createdAt: string;
  updatedAt: string;
  billingName?: string;
  shippingAddress?: ShippingAddress;
  parentGifts?: GiftEntity[];
  parentUuids?: string[];
  cardIsLinked?: boolean;
  cardId?: string;
  cardBrand?: string;
  cardMask?: string;
  cardDateLinked?: string;
  cardDateUnlinked?: string;
  cardDateUnenrolled?: string;
};

export type GiftActivityLog = GiftNoteEntity;

export type GiftHistory = {
  datetime: string;
  event: string;
  description: string;
  metadata: {
    amount: number;
    fee: number;
    merchant: string;
  };
};

export type PhysicalCardDetails = {
  nameOnCard: string;
  customMessage: string;
  firstName: string;
  lastName: string;
  carrier: CardCarrierFormValues;
  shippingUuid: string;
  faceplateUuid?: string;
  shipping?: ShippingAddress;
};

export type ShippingAddress = {
  firstName: string;
  lastName: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  postcode: string;
};

export type CalendarClaimDetails = {
  eventType: {
    id: number;
    slug: string;
    name: string;
  };
  serviceType: string;
  claimDateTime: string;
  supporterFirstName: string;
  supporterLastName: string;
};

export enum GiftStatus {
  Created = 'created', // Gift Entity has been created in the GiveInkind gift tracker system
  Ready = 'ready', // a Giftya gift have been created for the gift -> gift is ready to be redeemed
  // Pending = 'pending', // eGift is being redeemed as egift
  PendingEGift = 'pending-egift', // eGift is being redeemed as egift
  PendingLegacy = 'pending-legacy', // legacy gift is being redeemed as a legacy gift
  Redeemed = 'redeemed', // Gift has been redeemed (either as EGift, Visa, Zelle etc...)

  Used = 'used', // Gift has been used -> legacy fulfilled or linked card credits used up (egift is marked as used in a separate field)
  CardLinkMerge = 'card-link-merge',

  Swapped = 'swapped', // Gift Entity has been merged into a another Gift Entity
  Merged = 'merged', // Gift Entity has been merged into a another Gift Entity
  Refunded = 'refunded',

  Fulfilled = 'fulfilled', // Gift has been fulfilled by the remote service
}

export enum GiftPurchasedAs {
  Giftya = 'giftya',
  VisaPhysical = 'visa-physical',
  Visa = 'visa',
  Legacy = 'legacy',
}

export enum GiftPurchasedFrom {
  Calendar = 'calendar',
  ProductPage = 'product-page',
  Wishlist = 'wishlist',
}

export enum GiftRedemptionMethod {
  EGIFT = 'EGIFT',
  CARD_LINK = 'CARD_LINK',
  VISA = 'VISA',
  ZELLE = 'ZELLE',
  LEGACY = 'LEGACY',
  LEGACY_VISA = 'LEGACY_VISA',
  LEGACY_VISA_PHYSICAL = 'LEGACY_VISA_PHYSICAL',
}

export type GiftyaRedemtionMethod = {
  redemption_method: string;
  configuration: Record<string, unknown>;
};

export type EGiftSendEmailPayload = {
  giftUuid: string;
  code?: string;
  pin?: string;
  barcode?: string;
  emailAddresses: string[];
};

export type PGGiftSendEmailPayload = {
  giftUuid: string;
  delivery_url?: string;
  code?: string;
  activation_code?: string;
  pin?: string;
  emailAddresses: string[];
};

export type GiftSendThankYouMessagePayload = {
  giftUuid: string;
  customerId: string;
  greeting: string;
  message: string;
  signature: string;
  backgroundColor?: string;
  userId: string;
};

export type RegisterMissingCreditsPayload = {
  amount: number;
  isOnline: boolean;
  gift_id: string;
};

export interface BillingDetails {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  country?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  phone?: string;
  email?: string;
}

export interface CardCarrierFormValues extends BillingDetails {
  toName?: string;
  fromName?: string;
  message?: string;
  carrierType: PerfectgiftCarrierTypes;
  selectedCarrierDesign?: PGGreetingCardDesign;
}

export enum PerfectgiftCarrierTypes {
  GREETING_CARD = 'greeting_card',
  TRIFOLD_NO_ENVELOPE = 'trifold_no_envelope',
  TRIFOLD_WITH_ENVELOPE = 'trifold_with_envelope',
}

export type PGGreetingCardDesign = {
  url: string;
  uuid?: string;
  enabled: boolean;
};
