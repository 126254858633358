import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'redemption-modal';

const translations = {
  headerEntry: 'Ways to use',
  headerGiftValue: 'Gift Value',
  headerVisaCard: 'Visa Card Type',
  headerPhysicalVisaCard: 'Shipping Address',
  headerZelle: 'Zelle Account',
  headerReview: 'Review & Redeem',
  headerReviewActivate: 'Review & Activate',
  headerChooseBrand: 'Select Brand',
  linkToCreditCardHelperText:
    'Use your own payment card to pay instead of keeping track of a gift card, and get paid back immediately! Your gift can never be lost or stolen.',
  linkToCreditCardBtn: 'Activate to debit or credit card',
  useeGiftHelperText: 'Receive a standard eGift code in your Wallet to be scanned or entered at checkout.',
  useGiftLegacyHelperText: 'Receive a PerfectGift code in your Wallet entered at checkout.',
  useeGiftBtn: 'Use as an eGift',
  useeGiftLegacyBtn: 'Use as a PerfectGift',
  seeHowItWorksLink: 'See how it works',
  useAtAnotherMerchantBtn: 'Use at another merchant',
  changeTheBrandBtn: 'Change the brand',
  changeTheBrandHelperText: 'Swap your gift for one of 200+ brands',
  swapForVisaCardBtn: 'Swap for a Visa card',
  swapForVisaCardHelperText: 'Digital or physical. Use wherever Visa is accepted (restrictions may apply)',
  title: 'How would you like to use your <bold>{{service}}</bold> gift?',
  getDigitalVisaCardBtn: 'Get a Digital Visa card',
  getDigitalVisaCardHelperText: 'Immediately available. For online and phone purchases only.',
  getPhysicalVisaCardBtn: 'Get a Physical Visa card',
  getPhysicalVisaCardHelperText: 'Sent by mail within 2-7 business days. Shipping from $2.',
  useAtStoreBtn: 'Use at a store',
  useAtStoreHelperText: 'Swap your gift for one of 200+ brands.',
  useAsVisaBtn: 'Use as a Visa card',
  useAsVisaHelperText: 'Digital or physical. Use wherever Visa is accepted (restrictions may apply)',
  sendViaZelleBtn: 'Send funds via Zelle',
  sendViaZelleHelperText: 'Send to your bank account via Zelle. $4 processing fee.',
  toLinkCardBtn: 'To Activation',
  toGiftValuedBtn: 'To Gift Value',
  toReviewBtn: 'To Review',
  toAddressBtn: 'To Address',
  toAccountBtn: 'To Account',
  redeemBtn: 'Redeem',
  sendFundsBtn: 'Send Funds',
  toRedeemAndReviewBtn: 'Review & Activate',
  visaCardTitle: 'Select your preferred Visa card:',
  digitalVisaCardRadioTitle: 'Digital Visa',
  digitalVisaCardDescription: 'Immediately available in your Wallet. For online and phone purchases only',
  physicalVisaCardRadioTitle: 'Physical Visa',
  physicalVisaCardDescription: 'Sent by mail within 2-7 business days.',
  physicalVisaCardHelperText: 'Shipping from $2',
  physicalVisaStepTitle: 'Where can we send your Visa card?',
  zelleStepTitle: 'Your Zelle account details',
  firstName: 'First Name',
  lastName: 'Last Name',
  streetAddress: 'Street Address',
  city: 'City',
  state: 'State',
  zip: 'Zip Code',
  usAddressOnlyInfo: 'US Addresses Only',
  rememberMyZelleAccount: 'Remember my Zelle Account',
  email: 'Email',
  mobileNumber: 'Mobile Number',
  helperTypeZelle: '$4 processing fee deducted from the gift value before transfer',
  helperFixedValue: 'Available Denominations:',
  helperMinMaxValue: 'Min: {{minValue}} - Max: {{maxValue}}',
  errorFixedValue: 'The giftvalue is invalid.\n Please adjust the selected funds below.',
  errorMaxValue: 'Gift value max is {{maxValue}}. Adjust below.',
  errorMinValue: 'Gift value min is {{minValue}}.',
  warningMaxReached: 'Max Reached',
  infoPhysicalVisaCard: 'Shipping fee deducted from the gift value at redemption.',
  infoCardWithFee: '${{amount}} gift less ${{fee}} shipping fee',
  shippingMethodUSPS: '<bold>2-7 business days</bold> - USPS 1st Class, no tracking ($2)',
  shippingMethodFedex: '<bold>2-3 business days</bold> - FedEx, with tracking ($11)',
  giftType: 'Gift Type:',
  valueLabel: 'Value:',
  eGift: 'eGift',
  sendToZelle: 'Send To Zelle Account',
  shippingMethod: 'Shipping Method',
  sendTo: 'Send To',
  reviewInfo:
    'Once your request is submitted, <bold> you’ll no longer be able to select another redemption option<bold>.',
  cardTypeVisa: 'Visa',
  cardTypeMastercard: 'MasterCard',
  cardTypeAmex: 'Amex',
  howitWorksEgiftHeader: 'How does an eGift work?',
  howitWorksEgiftBody:
    'You’ll receive an eGift code in your Wallet. When in-store, have the cashier scan the code, or enter the code in the gift card section during online checkout.',
  howitWorksCardLinkingHeader: 'How does Card Activation work?',
  howitWorksCardLinkingBody:
    'Your <strong>{{name}}</strong> gift will be securely activated to your own debit or credit card ({{cards}}). When shopping at/ordering from <strong>{{name}}</strong>, simply use your own payment card to pay and the money will automatically be added back to your card account until your gift is used in full. Never worry about keeping track of a gift card again. Your gift will always be with you, and can never be lost or stolen',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
