import type { APIWithPagination, SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import { useApi, useApiLongerGETTimeout } from '@gik/core/api/swr/useApi';
import type { Product, ProductCheckoutType } from '@gik/models/gik/Product';

const PATH = 'products';

export interface ProductsParams extends APIWithPagination {
  productIds?: number[] | string[];
  forceCheckoutType?: ProductCheckoutType;
}

export default function getProducts(productIds: (number | string)[], perPage?: number) {
  return dotnetApi(PATH, {
    method: 'GET',
    searchParams: { include: productIds.join(','), perPage },
  }).json<Product[]>();
}

export function useProducts(params: ProductsParams, config?: SWRConfigInterface) {
  return useApiLongerGETTimeout<Product[]>(
    params.productIds?.length > 0 ? PATH : null,
    {
      include: params.productIds && params.productIds.join(','),
      perPage: params.perPage,
      forceCheckoutType: params.forceCheckoutType,
    },
    config
  );
}

export function getSuggestedProducts(situationIds: number[]) {
  return dotnetApi(PATH, {
    method: 'GET',
    searchParams: {
      suggestBySituationIds: situationIds?.join(','),
      perPage: 4,
    },
  }).json<Product[]>();
}

export function useSuggestedProducts(situationIds: number[], config?: SWRConfigInterface) {
  return useApi<Product[]>(
    situationIds?.length ? PATH : null,
    {
      suggestBySituationIds: situationIds?.join(','),
      perPage: 4,
    },
    config
  );
}

export interface emailRecipientInfoPayload {
  userId?: string; // for logged in users
  userEmail?: string; // for guests only, either this or userId must be provided
  subscribeToNewsletter?: boolean;
  inkindRouteId: string;
}

export function emailRecipientInfo(productId: number, payload: emailRecipientInfoPayload) {
  return dotnetApi
    .post(`${PATH}/${productId}/emailRecipientInfo`, {
      json: payload,
    })
    .json();
}
