import XIcon from '@heroicons/react/outline/XIcon';
import FilterIcon from '@heroicons/react/solid/FilterIcon';
import { matchSorter } from 'match-sorter';
import type { Moment } from 'moment';
import React from 'react';
import type { Row } from 'react-table';
import { useAsyncDebounce } from 'react-table';
import { DateRangePicker } from '../DatePicker';
import { HStack } from '../HStack/HStack';
import { Popover } from '../Popover';
import { SearchInput } from '../SearchInput';
import { SvgIcon } from '../SvgIcon';

export type TableGlobalFilterProps<D extends object = {}> = {
  preGlobalFilteredRows?: Row<D>;
  collapse: boolean;
  globalFilter: string;
  placeholder: string;
  setGlobalFilter?(v: string): void;
};

// Define a default UI for filtering
export function GlobalFilter({
  preGlobalFilteredRows,
  collapse,
  globalFilter,
  setGlobalFilter,
  placeholder,
}: TableGlobalFilterProps) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <SearchInput
      size="sm"
      value={value || ''}
      variant="lightgrey"
      onValueChange={value => {
        setValue(value);
        onChange(value);
      }}
      collapse={collapse}
      placeholder={placeholder}
    />
  );
}

export function DateRangeColumnFilter(props) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const { filterValue, setFilter } = props.column;

  const startDate = filterValue?.startDate;
  const endDate = filterValue?.endDate;

  const handleDatesChange = React.useCallback(
    (startDate: Moment, endDate: Moment) => {
      setFilter({ startDate, endDate });
      setIsOpen(false);
    },
    [setFilter]
  );

  const handleClick = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleClear = React.useCallback(() => {
    setFilter({ startDate: null, endDate: null });
    setIsOpen(false);
  }, [setFilter]);

  return (
    <Popover
      trigger={<SvgIcon className="gik-table-column-filter-trigger" Icon={FilterIcon} size="xs" />}
      className="gik-table-column-filter--date-range"
      triggerOnClick={handleClick}
      isOpen={isOpen}
      hoverable
      onClick={handleClick}
      onClose={handleClose}
    >
      <HStack>
        <DateRangePicker startDate={startDate} endDate={endDate} onChange={handleDatesChange} />
        {(startDate || endDate) && <SvgIcon Icon={XIcon} onClick={handleClear} />}
      </HStack>
    </Popover>
  );
}

export function fuzzyTextFilterFn(rows, id, filterValue) {
  // @ts-ignore
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;
