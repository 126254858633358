import type { UseMutationOptions } from '@tanstack/react-query';
import { usePlatformManagementClient } from '../../contexts';
import type {
  GiftEntity,
  GiftSendThankYouMessagePayload,
  PlatformManagementApiResponse,
  StrapiSingleDataSource,
  EGiftSendEmailPayload,
  PGGiftSendEmailPayload,
} from '@gik/platform-management-api-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { buildOptions } from '../../utils/buildOptions';

// type CreatePayload = GiftEntity;
// type UpdatePayload = Partial<GiftEntity>;

const uniqueName = 'gift';

// export const useGiftCreate = (
//   options?: UseMutationOptions<PlatformManagementApiResponse<StrapiSingleDataSource<GiftEntity>>, Error, CreatePayload>
// ) => {
//   const { client } = usePlatformManagementClient();
//   const queryClient = useQueryClient();
//   const key = `${uniqueName}_create`;

//   return useMutation<PlatformManagementApiResponse<StrapiSingleDataSource<GiftEntity>>, Error, CreatePayload>(
//     (payload: CreatePayload) => client.gift.create(payload),
//     buildOptions(queryClient, [key], options)
//   );
// };

// export const useGiftUpdate = (
//   options?: UseMutationOptions<PlatformManagementApiResponse<StrapiSingleDataSource<GiftEntity>>, Error, UpdatePayload>
// ) => {
//   const { client } = usePlatformManagementClient();
//   const queryClient = useQueryClient();
//   const key = `${uniqueName}_update`;

//   return useMutation<PlatformManagementApiResponse<StrapiSingleDataSource<GiftEntity>>, Error, UpdatePayload>(
//     (payload: UpdatePayload) => client.gift.update(payload.id.toString(), payload),
//     buildOptions(queryClient, [key], options)
//   );
// };

// export const useGiftDelete = (
//   options?: UseMutationOptions<PlatformManagementApiResponse<StrapiSingleDataSource<GiftEntity>>, Error, string>
// ) => {
//   const { client } = usePlatformManagementClient();
//   const queryClient = useQueryClient();
//   const key = `${uniqueName}_delete`;

//   return useMutation<PlatformManagementApiResponse<StrapiSingleDataSource<GiftEntity>>, Error, string>(
//     (id: string) => client.gift.delete(id),
//     buildOptions(queryClient, [key], options)
//   );
// };

export const useGiftSendEmail = (
  options?: UseMutationOptions<PlatformManagementApiResponse<boolean>, Error, EGiftSendEmailPayload>
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_send_email`;

  return useMutation<PlatformManagementApiResponse<boolean>, Error, EGiftSendEmailPayload>(
    (payload: EGiftSendEmailPayload) => {
      const { giftUuid, ...rest } = payload;
      return client.gift.sendEmail(giftUuid.toString(), rest);
    },
    buildOptions(queryClient, [key], options)
  );
};

export const useGiftSendLegacyEmail = (
  options?: UseMutationOptions<PlatformManagementApiResponse<boolean>, Error, PGGiftSendEmailPayload>
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_send_email`;

  return useMutation<PlatformManagementApiResponse<boolean>, Error, PGGiftSendEmailPayload>(
    (payload: PGGiftSendEmailPayload) => {
      const { giftUuid, ...rest } = payload;
      return client.gift.sendLegacyEmail(giftUuid.toString(), rest);
    },
    buildOptions(queryClient, [key], options)
  );
};

export const useGiftSendDigitalVisaEmail = (
  options?: UseMutationOptions<PlatformManagementApiResponse<boolean>, Error, PGGiftSendEmailPayload>
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_send_email`;

  return useMutation<PlatformManagementApiResponse<boolean>, Error, PGGiftSendEmailPayload>(
    (payload: PGGiftSendEmailPayload) => {
      const { giftUuid, ...rest } = payload;
      return client.gift.sendDigitalVisaEmail(giftUuid, rest);
    },
    buildOptions(queryClient, [key], options)
  );
};

export const useGiftSendThankYouMessage = (
  options?: UseMutationOptions<
    PlatformManagementApiResponse<StrapiSingleDataSource<GiftEntity>>,
    Error,
    GiftSendThankYouMessagePayload
  >
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_send_thank_you`;

  return useMutation<
    PlatformManagementApiResponse<StrapiSingleDataSource<GiftEntity>>,
    Error,
    GiftSendThankYouMessagePayload
  >(
    (payload: GiftSendThankYouMessagePayload) => {
      const { giftUuid, ...rest } = payload;
      return client.gift.sendThankYouMessage(giftUuid.toString(), rest);
    },
    buildOptions(queryClient, [key], options)
  );
};

export const useGiftMarkAsUsedByUuid = (
  options?: UseMutationOptions<PlatformManagementApiResponse<boolean>, Error, string>
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_mark_as_used`;

  return useMutation<PlatformManagementApiResponse<boolean>, Error, string>(
    (id: string) => client.gift.markAsUsedByUuid(id),
    buildOptions(queryClient, [key], options)
  );
};

export const useGiftMarkAsUnusedByUuid = (
  options?: UseMutationOptions<PlatformManagementApiResponse<boolean>, Error, string>
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_mark_as_used`;

  return useMutation<PlatformManagementApiResponse<boolean>, Error, string>(
    (id: string) => client.gift.markAsUnusedByUuid(id),
    buildOptions(queryClient, [key], options)
  );
};

export const useGiftUnlinkFromCard = (
  options?: UseMutationOptions<PlatformManagementApiResponse<boolean>, Error, string>
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_mark_as_used`;

  return useMutation<PlatformManagementApiResponse<boolean>, Error, string>(
    (id: string) => client.gift.unlinkFromCard(id),
    buildOptions(queryClient, [key], options)
  );
};

export const useGiftReactivateCard = (
  options?: UseMutationOptions<PlatformManagementApiResponse<boolean>, Error, string>
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_mark_as_used`;

  return useMutation<PlatformManagementApiResponse<boolean>, Error, string>(
    (id: string) => client.gift.reactivateCard(id),
    buildOptions(queryClient, [key], options)
  );
};
