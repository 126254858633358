import bemBlock from '@gik/core/utils/bemBlock';
import { ArrowButtonNext, ArrowButtonPrev } from '@gik/ui/ArrowButton';
import type { IIndicatorProps } from '@gik/ui/Indicator';
import { Indicator } from '@gik/ui/Indicator';
import type { UISize, UIVariant } from '@gik/ui/types';
import React from 'react';
import type { Settings } from 'react-slick';
import Slider from 'react-slick';
import useKey from 'react-use/lib/useKey';
import { Debug } from '@gik/core/utils/asPlaceholderReactNode';

export type ICarouselProps = Omit<Settings, 'responsive'> & {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  arrowButtonSize?: UISize;
  hideDisabledArrowButton?: boolean;
  aspectRatio?: number;
  dotsInside?: boolean;
  bindArrowsToKeyboard?: boolean;
  legacyIndicator?: boolean;
  variant?: UIVariant | IIndicatorProps['variant'];
  onImageClicked?: () => void;
  transparencyMask?: boolean;
};

export const defaultSlideAnimationSpeed = 500;

/**
 * @see https://react-slick.neostack.com/docs/api
 */
function CarouselComp(
  {
    children,
    dots = true,
    dotsInside = false,
    arrows = true,
    className,
    slidesToShow: _slidesToShow = 1,
    variant = 'default',
    arrowButtonSize,
    aspectRatio,
    style,
    legacyIndicator = false,
    bindArrowsToKeyboard,
    hideDisabledArrowButton = false,
    onImageClicked,
    transparencyMask = true,
    ...sliderProps
  }: ICarouselProps,
  ref: React.LegacyRef<Slider>
): React.ReactElement {
  const slides = getValidChildren(children);
  const bem = bemBlock('carousel');
  const innerRef = React.useRef(null);
  const combinedRef = useCombinedRefs<Slider>(ref, innerRef);
  const [slideNumber, setSlideNumber] = React.useState<number>(sliderProps.initialSlide ?? 0);

  const slidesToShow = Math.floor(_slidesToShow);
  const slidesToScroll = Math.floor(sliderProps.slidesToScroll ?? 1);

  const previousSlide = React.useCallback(
    (ev?: React.MouseEvent<HTMLDivElement>) => {
      ev?.stopPropagation();
      combinedRef?.current.slickPrev();
    },
    [combinedRef]
  );

  const nextSlide = React.useCallback(
    (ev?: React.MouseEvent<HTMLDivElement>) => {
      ev?.stopPropagation();
      combinedRef?.current.slickNext();
    },
    [combinedRef]
  );

  useKey('ArrowLeft', () => {
    if (!bindArrowsToKeyboard) return;
    previousSlide();
  });

  useKey('ArrowRight', () => {
    if (!bindArrowsToKeyboard) return;
    nextSlide();
  });

  const totalSlides = Math.ceil(React.Children.toArray(children).length / slidesToScroll);

  function getValidChildren(children: React.ReactNode) {
    return React.Children.toArray(children).filter(child => React.isValidElement(child)) as React.ReactElement[];
  }

  const hasPagination = slides.length > slidesToShow;
  const isPrevButtonDisabled = sliderProps?.infinite === false && slideNumber === 0;
  const isNextButtonDisabled =
    sliderProps?.infinite === false && slideNumber >= Math.max(slides.length - 1, 0) - slidesToScroll;

  const portalledDots = legacyDots => {
    if (!legacyIndicator && dots) {
      const currentStep = slideNumber / slidesToScroll;

      return (
        <div id={bem('indicator-wrapper')} onClick={ev => ev.stopPropagation()}>
          <Indicator
            variant={variant as IIndicatorProps['variant']}
            currentStep={currentStep}
            totalSteps={totalSlides}
            onIndicatorClick={(step, ev: React.MouseEvent<HTMLLIElement>) => {
              combinedRef?.current.slickGoTo(step * slidesToScroll);
            }}
          />
        </div>
      );
    } else {
      return <ul>{legacyDots}</ul>;
    }
  };

  const sliderClasses = bem('slider', [
    { 'has-dots': dots && hasPagination },
    { 'has-arrows': arrows && hasPagination },
  ]);

  const props: Partial<ICarouselProps> = {
    slidesToShow: _slidesToShow,
    arrows: false,
    adaptiveHeight: false,
    autoplay: false,
    dots,
    dotsInside,
    appendDots: portalledDots,
    speed: defaultSlideAnimationSpeed,
    className: sliderClasses,
    ...sliderProps,
    slidesToScroll: 1,
    beforeChange: (currentSlide: number, nextSlide: number) => {
      const next = Math.floor(currentSlide + 1 * Math.sign(Math.ceil(nextSlide) - currentSlide));

      // Math.max/min assure the number will never go out of the slide bounds
      const _nextSlide = Math.max(Math.min(next, slides.length - 1), 0);
      setSlideNumber(_nextSlide);
      sliderProps.beforeChange?.(currentSlide, _nextSlide);
    },
  };

  const handleImageClick = React.useCallback(() => {
    onImageClicked?.();
  }, [onImageClicked]);

  if (!style) style = {};
  if (aspectRatio) style['--aspect-ratio'] = aspectRatio * 100 + '%';

  return (
    <div
      className={bem(
        null,
        [
          { [variant]: variant },
          { fade: props.fade },
          { ['aspect-ratio']: aspectRatio },
          { ['dots-inside']: dotsInside },
          { 'transparency-mask': transparencyMask },
        ],
        className
      )}
      onClick={handleImageClick}
      style={style}
    >
      <Slider ref={combinedRef} {...props}>
        {children}
      </Slider>
      {arrows && hasPagination && ((isNextButtonDisabled && !hideDisabledArrowButton) || !isNextButtonDisabled) && (
        <ArrowButtonNext
          className={bem('arrow-next')}
          disabled={isNextButtonDisabled}
          onClick={nextSlide}
          size={arrowButtonSize}
        />
      )}
      {arrows && hasPagination && ((isPrevButtonDisabled && !hideDisabledArrowButton) || !isPrevButtonDisabled) && (
        <ArrowButtonPrev
          className={bem('arrow-prev')}
          disabled={isPrevButtonDisabled}
          onClick={previousSlide}
          size={arrowButtonSize}
        />
      )}
      <Debug data={{ slideNumber }} />
    </div>
  );
}

// We need a reference to slick in our core carousel component, but also forward it to any component that uses our carousel
// see https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd
// TODO: move this into our shared hooks
function useCombinedRefs<T>(...refs) {
  const targetRef = React.useRef<T>();

  React.useEffect(() => {
    refs.forEach(ref => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}

export const Carousel = React.forwardRef(CarouselComp);
