import type { UIComponent } from '@gik/core/types/UI';
import { sanitizeText, wrapUrlsWithAnchorTags } from '@gik/core/utils/Text';
import React from 'react';
import { HTMLParser } from '../HTMLParser';
import { nl2br } from '@gik/core/utils/string';
import he from 'he';

export type TextProps = {
  parseUrls?: boolean;
  html?: boolean;
  newLinesToBreak?: boolean;
  heDecode?: boolean;
} & UIComponent;

export function Text({
  children,
  newLinesToBreak,
  heDecode,
  className,
  parseUrls,
  html,
}: React.PropsWithChildren<TextProps>): React.ReactElement {
  const elements = Array.isArray(children) ? children : [children];

  return (
    <>
      {elements.map((element, index) => {
        if (typeof element === 'string') {
          let output = html ? element : sanitizeText(element);
          if (parseUrls) output = wrapUrlsWithAnchorTags(output);
          if (parseUrls || html) return <HTMLParser key={index} rawHtml={output} />;

          if (heDecode) {
            return he.decode(output);
          }
          if (newLinesToBreak) {
            return nl2br(output);
          }

          return output;
        }

        return <React.Fragment key={index}>{element}</React.Fragment>;
      })}
    </>
  );
}
