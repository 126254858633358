import { logger } from '@gik/analytics/utils/logger';
import type { CustomError } from '@gik/api/CustomError';
import { CustomStatusCodes } from '@gik/api/CustomStatusCodes';
import { facebookCodeExchange } from '@gik/api/users/auth';
import type { IOAuthUserLoginResponse } from '@gik/api/users/types';
import { auth } from '@gik/auth/auth';
import { OAuthErrorModalContent } from '@gik/auth/components/Login/OAuthErrorModalContent';
import { useAuthStore } from '@gik/auth/store/AuthStore';
import type { WindowWithFacebookJSSDK } from '@gik/auth/types/WindowWithFacebookJSSDK';
import type { WindowWithGoogleAuth } from '@gik/auth/types/WindowWithGoogleAuth';
import {
  facebookLoginFlow,
  facebookRerequest,
  useBaseFacebookDialogParams,
  useFacebookJSSDK,
} from '@gik/auth/utils/FacebookOAuth';
import { ExternalLoginProvider } from '@gik/auth/utils/LoginProvider';
import routes from '@gik/core/routes';
import { useEnvStore } from '@gik/core/store/EnvStore';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { useIsInApp } from '@gik/core/utils/browser';
import { validator } from '@gik/core/utils/validator';
import { getInkindPageViewHistory } from '@gik/inkind-page/components/InkindPageViewRecorder/InkindPageViewRecorder';
import { Button } from '@gik/ui/Button';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import { useRouter } from 'next/router';
import React from 'react';
import LoginProviders from './LoginProviders';

declare const window: WindowWithGoogleAuth & WindowWithFacebookJSSDK;

export interface IExternalLoginFormProps {
  className?: string;
  filter?: ExternalLoginProvider;
  actionLabel?: string;
  onSuccess(values: IOAuthUserLoginResponse);
  onIncorrectProvider?: (email: string) => Promise<void>;
  vertical?: boolean;
  onLoadingStart?(): void;
  onLoadingFinish?(): void;
  walletId?: string;
}

// FIXME: remove postUrl, returnUrl
export default function ExternalLoginForm({
  className = '',
  onSuccess,
  onIncorrectProvider,
  filter,
  actionLabel = '',
  vertical,
  onLoadingStart,
  onLoadingFinish,
  walletId,
}: React.PropsWithChildren<IExternalLoginFormProps>): JSX.Element {
  const bem = useBemCN('external-login-form-wrapper');
  const subscribeToNewsletter = useAuthStore(state => state.signupFormValues?.subscribeToNewsletter);
  const [isLoading, setIsLoading] = React.useState(false);
  const history = getInkindPageViewHistory();

  const router = useRouter();
  const code = router.query.code as string;

  // in-app browser flow
  React.useEffect(() => {
    (async () => {
      if (!code) return;

      const state = router.query.state as string;
      let redirectUri: string;
      try {
        redirectUri = JSON.parse(state).redirectUri;
      } catch (e) {
        // do nothing
      }

      setIsLoading(true);
      onLoadingStart?.();
      // make backend call to exchange code for access token
      // from in-app browser, redirectUrl is force enabled, parameter `code` needs to be sent to backend to retrieve an access token
      // see https://developers.facebook.com/docs/facebook-login/guides/advanced/manual-flow/#exchangecode

      try {
        // TODO: should redirect Uri be the path to continue after successful login?
        const response = await facebookCodeExchange(location.origin + routes.inAppLogin, code);

        if (response.access_token) {
          await handleOAuthSuccess(ExternalLoginProvider.FACEBOOK, response.access_token, redirectUri, () => {
            facebookRerequest(
              true,
              (accessToken, rerequestFlowCallback) =>
                handleOAuthSuccess(ExternalLoginProvider.FACEBOOK, accessToken, redirectUri, rerequestFlowCallback),
              redirectUri
            );
          });
        }
      } catch (e) {
        logger.error(e);
      }
    })();
    // eslint-disable-next-line
  }, [code]);

  async function handleOAuthSuccess(
    provider: ExternalLoginProvider,
    accessToken: string,
    redirectUrl?: string,
    rerequestFlowCallback?: (err?: CustomError) => void
  ) {
    setIsLoading(true);
    onLoadingStart?.();

    //setErrorMessage(null);
    try {
      const response = await auth.executeOauthLogin(
        provider,
        accessToken,
        history?.[0]?.inkindRouteId,
        subscribeToNewsletter,
        walletId
      );

      if (onSuccess) {
        onSuccess({
          ...response,
          ...(redirectUrl ? { redirectUrl } : {}),
        });
      } else {
        logger.error('No onSuccess callback provided');
      }
    } catch (err) {
      // TODO: better error handling
      if ((err as CustomError).code === CustomStatusCodes.IncorrectProvider) {
        if (onIncorrectProvider) {
          return void (await onIncorrectProvider?.(
            (err as CustomError).message.split(' ').find(word => validator.isEmail(word))
          ));
        }

        UI.alert(<OAuthErrorModalContent oauthErrorMessage={(err as CustomError).message} />, {
          closable: true,
          title: 'Login error',
          centeredTitle: false,
          autowidth: true,
        });
      } else if ((err as CustomError).code === CustomStatusCodes.IncompleteOAuthInfo) {
        rerequestFlowCallback?.(err);
      } else {
        UI.error(<p>{err.message || 'Something went wrong. Please try again.'}</p>, { title: 'Login' });
      }
    }
    setIsLoading(false);
    onLoadingFinish?.();
  }

  if (isLoading) {
    return <LoadingSpinner center />;
  }

  return (
    <>
      <div {...bem(null, [{ vertical }], [className])}>
        {LoginProviders.filter(provider => {
          if (filter) {
            return provider.value == filter;
          } else {
            return true;
          }
        }).map(provider => (
          <ProviderLoginButton
            key={provider.value}
            provider={provider.value}
            onSuccess={(accessToken, rerequestFlowCallback) =>
              handleOAuthSuccess(provider.value, accessToken, null, rerequestFlowCallback)
            }
            render={(onClick, disabled) => (
              <Button
                prepend={<SvgIcon Icon={provider.icon} />}
                className={`social-login ${provider.className}`}
                type="submit"
                size="lg"
                fullWidth
                onClick={() => {
                  onClick?.();
                }}
                disabled={disabled}
                preventClickWhenDisabled
              >
                {`${actionLabel} ${provider.label}`.trim()}
              </Button>
            )}
          />
        ))}
      </div>
    </>
  );
}

type ProviderLoginButtonProps = {
  provider: ExternalLoginProvider;
  onSuccess(accessToken: string, rerequestFlowCallback?: (err?: CustomError) => void): void;
  render: (onClick: () => void, disabled: boolean) => JSX.Element;
};

export function ProviderLoginButton({ provider, onSuccess, render }: ProviderLoginButtonProps) {
  switch (provider) {
    case ExternalLoginProvider.GOOGLE:
      return <GoogleLoginButton onSuccess={onSuccess} render={render} />;
    case ExternalLoginProvider.FACEBOOK:
      return <FacebookLoginButton onSuccess={onSuccess} render={render} />;
    default:
      throw new Error('Unknown provider: ' + provider);
  }
}

function GoogleLoginButton({ onSuccess, render }: Omit<ProviderLoginButtonProps, 'provider'>) {
  const client_id = useEnvStore(state => state.GOOGLE_APP_ID);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const isIAB = useIsInApp();

  const auth2 = React.useRef<gapi.auth2.GoogleAuth>();

  React.useEffect(() => {
    if (!auth2.current) {
      window?.gapi?.load?.('auth2', function () {
        auth2.current = window?.gapi.auth2.init({
          client_id,
          ux_mode: isIAB ? 'redirect' : 'popup',
          redirect_uri: isIAB ? location.origin + routes.inAppLogin : undefined,
          scope: 'openid email profile',
        });

        setLoaded(true);
      });
    }
  }, [client_id, isIAB]);

  // Google blocks authorization from embedded webview
  // @see https://developers.googleblog.com/2021/06/upcoming-security-changes-to-googles-oauth-2.0-authorization-endpoint.html
  if (isIAB) return null;

  return (
    <>
      {render?.(() => {
        auth2.current
          .signIn()
          .then(response => {
            onSuccess(response.getAuthResponse().access_token);
          })
          .catch(err => logger.error(err));
      }, !loaded)}
    </>
  );
}

function FacebookLoginButton({ onSuccess, render }: Omit<ProviderLoginButtonProps, 'provider'>) {
  useFacebookJSSDK();
  const isIAB = useIsInApp();
  const dialogParams = useBaseFacebookDialogParams();

  const rerequestFlowCallback = React.useCallback(() => {
    facebookRerequest(isIAB, onSuccess);
  }, [isIAB, onSuccess]);

  return (
    <>
      {render?.(
        () =>
          facebookLoginFlow(
            dialogParams,
            response => onSuccess(response.authResponse.accessToken, rerequestFlowCallback),
            response => logger.error(response),
            isIAB
          ),
        false
      )}
    </>
  );
}
